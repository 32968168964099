// // import AuthContext from "../Components/AuthProvider";
// // import { useNavigate } from "react-router-dom";
// // import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
// // import DropdownButton from 'react-bootstrap/DropdownButton';
// // import Button from 'react-bootstrap/Button';
// // import "../Components/Style.css";
// // import ipaddress from "../Components/IpAddress";
// // import Dropdown from 'react-bootstrap/Dropdown';
// // import InviceService from "../services/InviceService"
// // import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// // import DatePicker from "react-datepicker";

// // import contachimage from "../services/contacts.png"
// // import DGDCimage from "../Images/DGDC.png";
// // import {
// //     Card,
// //     CardBody,
// //     Container,
// //     Row,
// //     Col,
// //     Form,
// //     FormGroup,
// //     Label,
// //     Input,
// //     Table,
// // } from "reactstrap";
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faAngleRight, faAtom, faBolt, faBoxesPacking, faHandHoldingHand, faHistory, faList, faList12, faListAlt, faPencil, faPlaneDeparture, faPlus, faPlusCircle, faSearch, faUserCircle, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
// // import { faEdit } from '@fortawesome/free-solid-svg-icons';
// // import { faTrash } from '@fortawesome/free-solid-svg-icons';
// // import { faCheck, faSave, faTimes, faSyncAlt, faCancel, faCog, faPrint, faXmark, faFileLines, faChessKing } from '@fortawesome/free-solid-svg-icons';
// // import axios from "axios";
// // import { toast } from "react-toastify";
// // import "../Components/Style.css";
// // import { Pagination } from "react-bootstrap";
// // import jsPDF from "jspdf";
// // import { Line, PDFDownloadLink } from "@react-pdf/renderer";
// // import {
// //     Document,
// //     Page,
// //     Text,
// //     View,
// //     StyleSheet,
// //     PDFViewer,
// //     Image,
// // } from "@react-pdf/renderer";

// // import { BlobProvider } from "@react-pdf/renderer";
// // import { data } from "jquery";
// // import Rate_Chart_Service from "../services/Rate_Chart_Service";
// // import ReactLoading from 'react-loading';
// // import Select from 'react-select';


// // export default function GenerateSER() {

// //     const styles = {
// //         overlay: {
// //             position: 'fixed',
// //             top: 0,
// //             left: 0,
// //             width: '100%',
// //             height: '100%',
// //             backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
// //             display: 'flex',
// //             justifyContent: 'center',
// //             alignItems: 'center',
// //             zIndex: 9999, // Ensure the overlay is above other elements
// //         },
// //     };

// //     const [loading, setLoading] = useState(false);

// //     const navigate = useNavigate();
// //     const {
// //         jwtToken,
// //         userId,
// //         username,
// //         branchId,
// //         companyid,
// //         role,
// //         companyname,
// //         branchname,
// //         login,
// //         logout,
// //     } = useContext(AuthContext);
// //     const [getAirway, setAirway] = useState('');
// //     const index1 = 3;
// //     const firstPart = getAirway.slice(0, index1).replace(/[^0-9]/g, '');
// //     const secondPart = getAirway.slice(index1).replace(/[^0-9]/g, '');
// //     const [flag, setFlag] = useState('N');
// //     const { isAuthenticated } = useContext(AuthContext);
// //     const [isModalOpen, setIsModalOpen] = useState(false);
// //     // const [nsdlStatusArray, setNsdlStatusArray] = useState([]);
// //     const [selectednsdlStatus, setSelectednsdlStatus] = useState('');
// //     const [parties, setPartiesAll] = useState([]);
// //     const [selectedParty, setSelectedParty] = useState(null);
// //     const inputRef1 = useRef();
// //     const [errors, setErrors] = useState({});



// //     // const findNsdlStatus = async () => {
// //     //     const PCKTYPEResponse = await Rate_Chart_Service.getJarByDtl(companyid, 'J00041');
// //     //     const partyOptions = PCKTYPEResponse.data.map(jar => ({
// //     //         value: jar[1],
// //     //         label: jar[1]
// //     //     }));
// //     //     setNsdlStatusArray(partyOptions);
// //     // };

// //     const hoverScanAWB = () => {
// //         setTimeout(() => {
// //             inputRef1.current.focus();
// //         }, 100
// //         );
// //     }

// //     const openModal = () => {
// //         setIsModalOpen(true);
// //         hoverScanAWB();
// //     }

// //     const closeModal = () => {
// //         setIsModalOpen(false);
// //         setAirway('');
// //         setGetdata([]);
// //         setItems([]);
// //         setFlag('N');
// //         setSelectednsdlStatus('N');
// //     }





// //     // If the user is not authenticated, redirect to the login page
// //     useEffect(() => {
// //         if (!isAuthenticated) {
// //             navigate(
// //                 "/login?message=You need to be authenticated to access this page."
// //             );
// //         }
// //     }, [isAuthenticated, navigate]);
// //     const [getlink, setGetLink] = useState("");
// //     const getScan = {
// //         companyId: companyid,
// //         branchId: branchId,
// //         createdBy: userId,
// //         editedBy: userId,
// //         approvedBy: userId,
// //         link: '',
// //         status: "A",
// //     }
// //     const [items, setItems] = useState([]);
// //     const [getdata, setGetdata] = useState([]);





// //     const getExportForAirWayBill = async (companyid, branchId, scanData) => {
// //         setLoading(true);
// //         try {
// //             const response = await Rate_Chart_Service.getExportForAirWay(companyid, branchId, scanData);
// //             setItems(response.data);
// //             if (!response.data) {
// //                 toast.error('Data not found!', {
// //                     position: toast.POSITION.TOP_CENTER,
// //                     autoClose: 900,
// //                 });
// //             }
// //             else {
// //                 setSelectednsdlStatus(response.data.nsdlStatus);
// //                 openModal();
// //             }
// //         } catch (error) {
// //             toast.error('Oops something went wrong!', {
// //                 position: toast.POSITION.TOP_CENTER,
// //                 autoClose: 900,
// //             });
// //             console.error("Error in getExportForAirWayBill request:", error);
// //         } finally {
// //             setGetLink('');
// //             setLoading(false);
// //         }

// //     };


// //     const findPartiesAll = async () => {

// //         const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);

// //         const partyOptions = partyResponse.data.map(party => ({
// //             value: party[0],
// //             label: party[1]
// //         }));
// //         setPartiesAll(partyOptions);

// //     };

// //     const handleChange = (event) => {
// //         const { name, value } = event.target;

// //         const formattedValue = formatValue(name, value);

// //         setErrors((prevErrors) => ({
// //             ...prevErrors,
// //             [name]: '',
// //         }));

// //         setItems((prevData) => ({
// //             ...prevData,
// //             [name]: formattedValue,
// //         }));
// //     }

// //     const [getAllFlight, setGetAllFlight] = useState([]);
// //     const getFlightlist = () => {
// //         axios
// //             .get(`https://${ipaddress}Airline/list/${companyid}/${branchId}`)
// //             .then((response) => {
// //                 // console.log("GET list response:", response.data);
// //                 setGetAllFlight(response.data); // Store the list in the state

// //             })
// //             .catch((error) => {
// //                 console.error("GET list error:", error);
// //             });
// //     };

// //     useEffect(() => {
// //         findPartiesAll();
// //         getFlightlist();
// //     }, [companyid, branchId])

// //     const [saveairname, setSaveairname] = useState([]);
// //     const getSingleAirlineName = () => {
// //         axios
// //             .get(`https://${ipaddress}Airline/find1/${companyid}/${branchId}/${items.airlineCode}`)
// //             .then((response) => {
// //                 // console.log("GET list response:", response.data);
// //                 setSaveairname(response.data); // Store the list in the state

// //             })
// //             .catch((error) => {
// //                 console.error("GET list error:", error);
// //             });
// //     };

// //     useEffect(() => {
// //         getSingleAirlineName();
// //     }, [companyid, branchId, items.airlineName])

// //     const [saveaircodename, setSaveaircodename] = useState([]);
// //     const getSingleAirlineCode = () => {
// //         axios
// //             .get(`https://${ipaddress}Airline/findByCode/${companyid}/${branchId}/${saveairname.airlineCode}`)
// //             .then((response) => {
// //                 // console.log("GET list response:", response.data);
// //                 setSaveaircodename(response.data); // Store the list in the state

// //             })
// //             .catch((error) => {
// //                 console.error("GET list error:", error);
// //             });
// //     };



// //     useEffect(() => {
// //         getSingleAirlineCode();
// //     }, [companyid, branchId, saveairname.airlineCode])

// //     const [getaircode, setGetaircode] = useState('');

// //     useEffect(() => {
// //         setGetaircode(items.airlineCode)

// //     }, [items.airlineCode])

// //     useEffect(() => {
// //         if (getAirway.length >= 11) {
// //             const timer = setTimeout(() => {

// //                 items.airlineCode = firstPart.replace(/[^0-9]/g, '');
// //                 const airway = firstPart + secondPart.replace(/[^0-9]/g, '');
// //                 items.airwayBillNo = airway.slice(0, 11);
// //                 setAirway('');
// //                 getSingleAirlineName();
// //             }, 100);

// //             return () => clearTimeout(timer);
// //         }
// //     }, [getAirway]);



// //     useEffect(() => {

// //         if (
// //             !items.serNo &&
// //             saveairname.airlineName &&
// //             items.airwayBillNo &&
// //             items.airwayBillNo.length >= 11
// //         ) {
// //             handleSubmit1();
// //         }
// //     }, [items, saveairname]);

// //     useEffect(() => {
// //         const foundType2 = parties.find(type => type.value === items.nameOfExporter);
// //         setSelectedParty(foundType2 ? foundType2 : null);
// //         if (foundType2) {
// //             setErrors(prevErrors => ({
// //                 ...prevErrors,
// //                 party: '',
// //             }));
// //         }

// //     }, [parties, items.nameOfExporter]);


// //     const handleSubmit1 = async () => {
// //         console.log("***CALLING***");

// //         if (saveairname.airlineName === '' || saveairname.airlineName === undefined || saveairname.airlineName === null) {
// //             toast.error("Please enter correct airway bill number.", {
// //                 autoClose: 700
// //             });
// //             return;
// //         }
// //         if (items.airwayBillNo === '') {
// //             toast.error("Airway Bill No. is required", {
// //                 autoClose: 700
// //             });
// //             return;
// //         } else {
// //             if (items.airwayBillNo.length < 11) {
// //                 toast.error("Please enter an airway bill number greater than 11 digits.", {
// //                     autoClose: 700
// //                 });
// //                 return;
// //             }
// //         }

// //         const newErrors = {};
// //         if (!items.nameOfExporter) {
// //             newErrors.party = 'Select Party';
// //         }
// //         if (!items.grossWeight) {
// //             newErrors.grossWeight = 'Gross Weight is required';
// //         }

// //         setErrors(newErrors);

// //         if (Object.keys(newErrors).length > 0) {

// //             console.log(newErrors);
// //             return;
// //           }

// //         const submitBtn = document.getElementById("submitBtnnn");
// //         items.airlineName = saveairname.airlineName;
// //         items.flightNo = saveairname.flightNo;
// //         submitBtn.disabled = true;

// //         setLoading(true);
// //         try {
// //             const response = await Rate_Chart_Service.updateExportAirWay(companyid, branchId, userId, selectednsdlStatus, items);
// //             setItems(response.data);

// //             const toastContent = `Airway bill number of SER <strong> ${response.data.serNo} </strong> has been updated`


// //             // await printBarcode(response.data.sbNo, response.data.serNo, response.data.noOfPackages, response.data.serDate, response.data.sbDate, "N", "1232");

           
// //             toast.success(<div dangerouslySetInnerHTML={{ __html: toastContent }} />, {
// //                 position: toast.POSITION.TOP_CENTER,
// //                 autoClose: 1200,
// //                 style: { width: '35vw' },
// //             });
// //         } catch (error) {
// //             console.error("Error in handleSubmit1 request:", error);
// //             toast.error("An error occurred while updating the export airway. Please try again.", {
// //                 autoClose: 1000,
// //                 style: { width: '36vw' }
// //             });
// //         } finally {
// //             setLoading(false);
// //             submitBtn.disabled = false;
// //         }
// //     };


// //     const handleFormSubmit = async (event) => {
// //         event.preventDefault();
// //         await getExportForAirWayBill(companyid, branchId, getlink);
// //     };


// //     const inputRef = useRef();

// //     const handleKeyPress = (event) => {
// //         if (event.key === 'Enter') {
// //             event.preventDefault();
// //             document.getElementById("submitButton").click();
// //         }
// //     };


// //     const printBarcode = async (mawb, seino, nop, sirdate, reqdate, niptStatus, requestId) => {
// //         try {
// //             const response = await InviceService.getbarcode(mawb, seino, nop, sirdate, reqdate, "EXPORT", niptStatus, requestId);

// //             // Check if the response status is OK (200)
// //             if (response.status === 200) {
// //                 // Get the raw response data as base64-encoded string
// //                 const newWindow = window.open('', '_blank');
// //                 newWindow.document.write(response.data);
// //                 setTimeout(() => {
// //                     newWindow.print(); // Open the print dialog
// //                 }, 100);
// //                 closeModal();
// //             } else {
// //                 throw new Error('Failed to generate PDF');
// //             }
// //         } catch (error) {
// //         }
// //     };


// //     const handlePartyChange = async (selectedOption) => {
// //         setSelectedParty(selectedOption ? selectedOption : null);
// //         if (selectedOption) {
// //             setItems(prevState => ({
// //                 ...prevState,
// //                 nameOfExporter: selectedOption ? selectedOption.value : '',
// //                 partyName: selectedOption ? selectedOption.label : ''
// //             }));
// //             await getPartyByID(selectedOption ? selectedOption.value : '');
// //         }
// //         else {
// //             setItems(prevState => ({
// //                 ...prevState,
// //                 nameOfExporter:'',
// //                 iecCode: '',
// //                 entityId: '',
// //                 partyName: ''
// //             }));
// //         }
// //         setErrors(prevErrors => ({
// //             ...prevErrors,
// //             party: '',
// //         }));
// //     };



// //     const getPartyByID = async (partyId) => {
// //         try {
// //             const res = await Rate_Chart_Service.getPartyByIdNew(companyid, branchId, partyId, "EXP");
// //             if (res.data) {
// //                 const party = res.data;     


// //                 setItems(prevState => ({
// //                     ...prevState,
// //                     iecCode: party.iecNo || '',
// //                     entityId: party.entityId || '',
// //                     partyName: party.partyName || '',
// //                     chaNo: party.status,                    
// //                     consoleAgent: party.createdBy,
// //                 }));


// //             } else {
// //                 console.log("No data received from getPartyById API");
// //             }
// //         } catch (error) {
// //             console.error("Error fetching party data:", error);
// //         }
// //     };






// //     const formatValue = (name, value) => {
// //         const decimalFields = ['grossWeight', 'fobValueInINR'];
       
// //         if (decimalFields.includes(name)) {
// //             const match = value.match(/^\d*(\.\d{0,3})?/);
// //             return match ? match[0] : '';
// //         }
// //         return value;
// //     };




// //     return (
// //         <div className="Container" >

// //             {loading && (
// //                 <div style={styles.overlay}>
// //                     <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
// //                 </div>
// //             )}


// //             <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-50px' }} > <FontAwesomeIcon
// //                 icon={faBolt}
// //                 style={{
// //                     marginRight: '8px',
// //                     color: 'black',
// //                 }}
// //             /> Generate AWB & SER New</h5>

// //             <Card>
// //                 <CardBody>
// //                     <Form onSubmit={handleFormSubmit}>
// //                         <Row>
// //                             <Col md={4}>
// //                                 <FormGroup>
// //                                     <label className="forlabel" htmlFor="search">
// //                                         Enter SbNo / Scan SER No
// //                                     </label>
// //                                     <Input
// //                                         placeholder="Enter SbNo / Scan SER No"
// //                                         type="text"
// //                                         id="getlink"
// //                                         value={getlink}
// //                                         autoFocus
// //                                         ref={inputRef}
// //                                         onKeyDown={handleKeyPress}
// //                                         onChange={(e) => setGetLink(e.target.value)}
// //                                         className="inputField"
// //                                     />
// //                                 </FormGroup>
// //                             </Col>
// //                             <Col style={{ marginTop: 25, marginRight: 90, paddingRight: 90 }} md={2}>

// //                                 <Button
// //                                     className="allbutton"
// //                                     variant="outline-success"
// //                                     onClick={(e) => getExportForAirWayBill(companyid, branchId, getlink)}
// //                                     id="submitButton"
// //                                     style={{ marginRight: 5 }}
// //                                 >
// //                                     <FontAwesomeIcon icon={faSave} style={{ marginRight: "5px" }} />
// //                                     Submit
// //                                 </Button>
// //                             </Col>
// //                         </Row>
// //                     </Form>
// //                 </CardBody>
// //             </Card>

// //             <Modal Modal isOpen={isModalOpen} onClose={closeModal} toggle={closeModal} style={{ maxWidth: '800px', wioverflow: '-moz-hidden-unscrollable' }
// //             } >
// //                 <ModalHeader toggle={closeModal} style={{
// //                     backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font', textAlign: 'center', background: '#26a69a',
// //                     boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
// //                     border: '1px solid rgba(0, 0, 0, 0.3)',
// //                     borderRadius: '0',
// //                     backgroundColor: '#85144b',
// //                     backgroundColor: 'rgba(0, 0, 0, 0.3)',
// //                     backgroundImage: 'url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
// //                     backgroundSize: 'cover',
// //                     backgroundRepeat: 'no-repeat',
// //                     //backgroundPosition: 'center',
// //                     backgroundPosition: 'center',
// //                 }}>
// //                     <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font' }} > <FontAwesomeIcon
// //                         icon={faEdit}
// //                         style={{
// //                             marginRight: '8px',
// //                             color: 'black', // Set the color to golden
// //                         }}
// //                     /> Update Airway Bill Number</h5>

// //                 </ModalHeader>
// //                 <ModalBody style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover' }}>
// //                     <Row>
// //                         <Col md={3}>
// //                             <FormGroup>
// //                                 <Label className="forlabel" for="branchId">Request ID</Label><span className='error'>*</span>
// //                                 <Input
// //                                     type="text"
// //                                     name="challanNo"                                    
// //                                     maxLength={30}
// //                                     readOnly
// //                                     id="service"
// //                                     required
// //                                     tabIndex={-2}
// //                                     value={items.sbRequestId}
// //                                     className="inputField"

// //                                 />
// //                             </FormGroup>
// //                         </Col>
// //                         <Col md={3}>
// //                             <FormGroup>
// //                                 <Label className="forlabel" for="branchId">SB Number</Label><span className='error'>*</span>
// //                                 <Input
// //                                     type="text"
// //                                     name="challanNo"
// //                                     id="service"
// //                                     readOnly
// //                                     maxLength={30}
// //                                     requiretabIndex={-3}
// //                                     value={items.sbNo}
// //                                     className="inputField"

// //                                 />
// //                             </FormGroup>
// //                         </Col>
                       

// //                         <Col md={3} >
// //                                 <FormGroup>
// //                                     <Label className="forlabel" for="branchId">Gross Weight</Label><span className='error'>*</span>
// //                                     <div style={{ position: 'relative' }}>
// //                                         <Input
// //                                             type="text" name="grossWeight"
// //                                             className={errors.grossWeight ? 'error-border' : ''}
// //                                             value={items.grossWeight}
// //                                             placeholder="Enter Parcel's Gross Weight"                                           
// //                                             onChange={handleChange}
// //                                             maxLength={8}
// //                                         />
// //                                         {errors.grossWeight && (
// //                                             <div className="error-message">
// //                                                 {errors.grossWeight}
// //                                             </div>
// //                                         )}
// //                                     </div>
// //                                 </FormGroup>
// //                             </Col>

// //                             <Col md={3}>
// //                                 <FormGroup>
// //                                     <Label className="forlabel" for="branchId">Gross Weight Unit</Label>

// //                                     <Input
// //                                         type="select"
// //                                         name="uomGrossWeight"                                        
// //                                         value={items.uomGrossWeight}                                        
// //                                         onChange={handleChange}
// //                                     >
// //                                         <option value="GRAMS">GRAMS</option>
// //                                         <option value="KILO GRAMS">KILO GRAMS</option>
// //                                     </Input>

// //                                 </FormGroup>
// //                             </Col>
// //                     </Row>

// //                     <Row>

// //                     <Col md={2}>
// //                             <FormGroup>
// //                                 <Label className="forlabel" for="branchId">Nop</Label><span className='error'>*</span>
// //                                 <Input
// //                                     type="text"
// //                                     name="challanNo"
// //                                     id="service"
// //                                     maxLength={30}
// //                                     tabIndex={-3}
// //                                     readOnly
// //                                     required
// //                                     value={items.noOfPackages}
// //                                     className="inputField"

// //                                 />
// //                             </FormGroup>
// //                         </Col>
// //                         <Col md={6}>
// //                             <FormGroup>
// //                                 <Label className="forlabel" for="branchId">Select Party</Label><span className='error'>*</span>

// //                                 <div style={{ position: 'relative' }}>
// //                                     <Select
// //                                         options={parties}
// //                                         value={selectedParty}
// //                                         onChange={handlePartyChange}
// //                                         className={errors.party ? 'error-border' : ''}
// //                                         placeholder="Select a Party"
// //                                         isClearable
// //                                         styles={{
// //                                             control: (provided, state) => ({
// //                                                 ...provided,
// //                                                 border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
// //                                                 boxShadow: 'none',
// //                                                 '&:hover': {
// //                                                     border: '1px solid #ccc'
// //                                                 }
// //                                             }),
// //                                             indicatorSeparator: () => ({
// //                                                 display: 'none'
// //                                             }),
// //                                             dropdownIndicator: () => ({
// //                                                 display: 'none'
// //                                             })
// //                                         }}
// //                                     />
// //                                     {errors.party && (
// //                                         <div className="error-message">
// //                                             {errors.party}
// //                                         </div>
// //                                     )}
// //                                 </div>
// //                             </FormGroup>
// //                         </Col>
// //                         <Col md={4}>
// //                             <FormGroup>
// //                                 <Label className="forlabel" for="branchId">Airline</Label><span className='error'>*</span>
// //                                 <select
// //                                     id="airlineCode"
// //                                     className="form-control"
// //                                     name="airlineCode"
// //                                     onChange={handleChange}
// //                                     tabIndex={-5}
// //                                     value={items.airlineCode}
// //                                 >
// //                                     <option value="">Select Airline</option>
// //                                     {getAllFlight.map((data, index) => (
// //                                         <option key={index} value={data.airlineCode}>{data.airlineName}</option>
// //                                     ))}
// //                                 </select>
// //                             </FormGroup>
// //                         </Col>


// //                     </Row>


// //                     <Row>
// //                         <Col md={2}>
// //                             <FormGroup>
// //                                 <Label className="forlabel" for="branchId">Number</Label>
// //                                 <Input
// //                                     type="text"
// //                                     name="airlineCode"
// //                                     id="airlineCode"
// //                                     maxLength={30}
// //                                     tabIndex={-6}
// //                                     required
// //                                     value={items.airlineCode}
// //                                     className="inputField"

// //                                 />
// //                             </FormGroup>
// //                         </Col>
// //                         <Col md={4}>
// //                             <FormGroup>
// //                                 <Label className="forlabel" for="branchId">Airway Bill Number</Label><span className='error'>*</span>
// //                                 <Input
// //                                     type="text"
// //                                     name="airwayBillNo"
// //                                     id="airwayBillNo"
// //                                     tabIndex={-7}
// //                                     maxLength={30}
// //                                     required
// //                                     onChange={handleChange}
// //                                     value={items.airwayBillNo}
// //                                     className="inputField"

// //                                 />
// //                             </FormGroup>
// //                         </Col>
// //                         <Col className="text-center" style={{ marginTop: 34 }} >
// //                             <h4>OR</h4>
// //                         </Col>
// //                         <Col md={4}>
// //                             <FormGroup>
// //                                 <Label className="forlabel" for="branchId">Scan Airway Bill Number</Label>
// //                                 <input
// //                                     type="text"
// //                                     onChange={(e) => setAirway(e.target.value)}
// //                                     value={getAirway}
// //                                     ref={inputRef1}
// //                                     style={{ height: '38px', borderRadius: 4 }}
// //                                 />
// //                             </FormGroup>
// //                         </Col>
// //                     </Row>
// //                     <Row>

// //                         <Col className="text-center">
// //                              {items.serNo && (
// //                                 <Button onClick={() => { printBarcode(items.sbNo, items.serNo, items.noOfPackages, items.serDate, items.sbDate, "N", "1232") }} style={{ marginRight: 10 }} variant="outline-primary">
// //                                     Print SER
// //                                 </Button>
// //                             )
// //                             } 

// //                             <Button onClick={handleSubmit1} id="submitBtnnn"
// //                                 variant="outline-success"
// //                                 style={{ fontWeight: 'bold' }}>
// //                                 <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
// //                                 Submit
// //                             </Button>
// //                         </Col>
// //                     </Row>
// //                 </ModalBody>
// //             </Modal>
// //         </div>
// //     );
// // }



// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
// import DropdownButton from 'react-bootstrap/DropdownButton';
// import Button from 'react-bootstrap/Button';
// import "../Components/Style.css";
// import ipaddress from "../Components/IpAddress";
// import Dropdown from 'react-bootstrap/Dropdown';
// import InviceService from "../services/InviceService"
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import DatePicker from "react-datepicker";

// import contachimage from "../services/contacts.png"
// import DGDCimage from "../Images/DGDC.png";
// import {
//     Card,
//     CardBody,
//     Container,
//     Row,
//     Col,
//     Form,
//     FormGroup,
//     Label,
//     Input,
//     Table,
// } from "reactstrap";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleRight, faAtom, faBolt, faBoxesPacking, faHandHoldingHand, faHistory, faList, faList12, faListAlt, faPencil, faPlaneDeparture, faPlus, faPlusCircle, faReceipt, faSearch, faUserCircle, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
// import { faEdit } from '@fortawesome/free-solid-svg-icons';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import { faCheck, faSave, faTimes, faSyncAlt, faCancel, faCog, faPrint, faXmark, faFileLines, faChessKing } from '@fortawesome/free-solid-svg-icons';
// import axios from "axios";
// import { toast } from "react-toastify";
// import "../Components/Style.css";
// import { Pagination } from "react-bootstrap";
// import jsPDF from "jspdf";
// import { Line, PDFDownloadLink } from "@react-pdf/renderer";
// import {
//     Document,
//     Page,
//     Text,
//     View,
//     StyleSheet,
//     PDFViewer,
//     Image,
// } from "@react-pdf/renderer";

// import { BlobProvider } from "@react-pdf/renderer";
// import { data } from "jquery";
// import Rate_Chart_Service from "../services/Rate_Chart_Service";
// import ReactLoading from 'react-loading';
// import Select from 'react-select';


// export default function GenerateSER() {

//     const styles = {
//         overlay: {
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             zIndex: 9999, // Ensure the overlay is above other elements
//         },
//     };

//     const [loading, setLoading] = useState(false);

//     const navigate = useNavigate();
//     const {
//         jwtToken,
//         userId,
//         username,
//         branchId,
//         companyid,
//         role,
//         companyname,
//         branchname,
//         login,
//         logout,
//     } = useContext(AuthContext);
//     const [getAirway, setAirway] = useState('');
//     const index1 = 3;
//     const firstPart = getAirway.slice(0, index1).replace(/[^0-9]/g, '');
//     const secondPart = getAirway.slice(index1).replace(/[^0-9]/g, '');
//     const [flag, setFlag] = useState('N');
//     const { isAuthenticated } = useContext(AuthContext);
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     // const [nsdlStatusArray, setNsdlStatusArray] = useState([]);
//     const [selectednsdlStatus, setSelectednsdlStatus] = useState('');
//     const [parties, setPartiesAll] = useState([]);
//     const [selectedParty, setSelectedParty] = useState(null);
//     const inputRef1 = useRef();
//     const [errors, setErrors] = useState({});



//     // const findNsdlStatus = async () => {
//     //     const PCKTYPEResponse = await Rate_Chart_Service.getJarByDtl(companyid, 'J00041');
//     //     const partyOptions = PCKTYPEResponse.data.map(jar => ({
//     //         value: jar[1],
//     //         label: jar[1]
//     //     }));
//     //     setNsdlStatusArray(partyOptions);
//     // };

//     const hoverScanAWB = () => {
//         setTimeout(() => {
//             inputRef1.current.focus();
//         }, 100
//         );
//     }

//     const openModal = () => {
//         setIsModalOpen(true);
//         hoverScanAWB();
//     }

//     const closeModal = () => {
//         setIsModalOpen(false);
//         setAirway('');
//         setGetdata([]);
//         setItems([]);
//         setFlag('N');
//         setSelectednsdlStatus('N');
//     }





//     // If the user is not authenticated, redirect to the login page
//     useEffect(() => {
//         if (!isAuthenticated) {
//             navigate(
//                 "/login?message=You need to be authenticated to access this page."
//             );
//         }
//     }, [isAuthenticated, navigate]);
//     const [getlink, setGetLink] = useState("");
//     const getScan = {
//         companyId: companyid,
//         branchId: branchId,
//         createdBy: userId,
//         editedBy: userId,
//         approvedBy: userId,
//         link: '',
//         status: "A",
//     }
//     const [items, setItems] = useState([]);
//     const [getdata, setGetdata] = useState([]);





//     const getExportForAirWayBill = async (companyid, branchId, scanData) => {
//         setLoading(true);
//         try {
//             const response = await Rate_Chart_Service.getExportForAirWay(companyid, branchId, scanData);
//             console.log(response.data);
//             setItems(response.data);
//             if (!response.data) {
//                 toast.error('Data not found!', {
//                     position: toast.POSITION.TOP_CENTER,
//                     autoClose: 900,
//                 });
//             }
//             else {
//                 setSelectednsdlStatus(response.data.nsdlStatus);
//                 openModal();
//             }
//         } catch (error) {
//             toast.error('Oops something went wrong!', {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 900,
//             });
//             console.error("Error in getExportForAirWayBill request:", error);
//         } finally {
//             setGetLink('');
//             setLoading(false);
//         }

//     };


//     const findPartiesAll = async () => {

//         const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);

//         const partyOptions = partyResponse.data.map(party => ({
//             value: party[0],
//             label: party[1]
//         }));
//         setPartiesAll(partyOptions);

//     };

//     const handleChange = (event) => {
//         const { name, value } = event.target;

//         const formattedValue = formatValue(name, value);

//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             [name]: '',
//         }));

//         setItems((prevData) => ({
//             ...prevData,
//             [name]: formattedValue,
//         }));


//     }


//     const [getAllFlight, setGetAllFlight] = useState([]);
//     const getFlightlist = () => {
//         axios
//             .get(`https://${ipaddress}Airline/list/${companyid}/${branchId}`)
//             .then((response) => {
//                 // console.log("GET list response:", response.data);
//                 setGetAllFlight(response.data); // Store the list in the state

//             })
//             .catch((error) => {
//                 console.error("GET list error:", error);
//             });
//     };

//     useEffect(() => {
//         findPartiesAll();
//         getFlightlist();
//     }, [companyid, branchId])

//     const [saveairname, setSaveairname] = useState([]);
//     const getSingleAirlineName = () => {
//         axios
//             .get(`https://${ipaddress}Airline/find1/${companyid}/${branchId}/${items.airlineCode}`)
//             .then((response) => {
//                 // console.log("GET list response:", response.data);
//                 setSaveairname(response.data); // Store the list in the state

//             })
//             .catch((error) => {
//                 console.error("GET list error:", error);
//             });
//     };

//     useEffect(() => {
//         getSingleAirlineName();
//     }, [companyid, branchId, items.airlineName])

//     const [saveaircodename, setSaveaircodename] = useState([]);
//     const getSingleAirlineCode = () => {
//         axios
//             .get(`https://${ipaddress}Airline/findByCode/${companyid}/${branchId}/${saveairname.airlineCode}`)
//             .then((response) => {
//                 // console.log("GET list response:", response.data);
//                 setSaveaircodename(response.data); // Store the list in the state

//             })
//             .catch((error) => {
//                 console.error("GET list error:", error);
//             });
//     };



//     useEffect(() => {
//         getSingleAirlineCode();
//     }, [companyid, branchId, saveairname.airlineCode])

//     const [getaircode, setGetaircode] = useState('');

//     useEffect(() => {
//         setGetaircode(items.airlineCode)

//     }, [items.airlineCode])

//     useEffect(() => {
//         if (getAirway.length >= 11) {
//             const timer = setTimeout(() => {

//                 items.airlineCode = firstPart.replace(/[^0-9]/g, '');
//                 const airway = firstPart + secondPart.replace(/[^0-9]/g, '');
//                 items.airwayBillNo = airway.slice(0, 11);
//                 setAirway('');
//                 getSingleAirlineName();
//             }, 100);

//             return () => clearTimeout(timer);
//         }
//     }, [getAirway]);


//     useEffect(() => {
//         if (
//             !items.serNo &&
//             saveairname.airlineName &&
//             items.airwayBillNo &&
//             items.airwayBillNo.length >= 11
//         ) {
// console.log("Calling To handleSubmit1();");

//             handleSubmit1();
//         }
//     }, [items, saveairname]);

//     useEffect(() => {
//         const foundType2 = parties.find(type => type.value === items.nameOfExporter);
//         setSelectedParty(foundType2 ? foundType2 : null);
//         if (foundType2) {
//             setErrors(prevErrors => ({
//                 ...prevErrors,
//                 party: '',
//             }));
//         }

//     }, [parties, items.nameOfExporter]);


//     const handleSubmit1 = async () => {
//         console.log("***CALLING***");

//         if (saveairname.airlineName === '' || saveairname.airlineName === undefined || saveairname.airlineName === null) {
//             toast.error("Please enter correct airway bill number.", {
//                 autoClose: 700
//             });
//             return;
//         }
//         if (items.airwayBillNo === '') {
//             toast.error("Airway Bill No. is required", {
//                 autoClose: 700
//             });
//             return;
//         } else {
//             if (items.airwayBillNo.length < 11) {
//                 toast.error("Please enter an airway bill number greater than 11 digits.", {
//                     autoClose: 700
//                 });
//                 return;
//             }
//         }

//         const newErrors = {};
//         if (!items.nameOfExporter) {
//             newErrors.party = 'Select Party';
//         }
//         if (!items.grossWeight) {
//             newErrors.grossWeight = 'Gross Weight is required';
//         }

//         setErrors(newErrors);

//         if (Object.keys(newErrors).length > 0) {

//             console.log(newErrors);
//             return;
//         }

//         const submitBtn = document.getElementById("submitBtnnn");
//         items.airlineName = saveairname.airlineName;
//         items.flightNo = saveairname.flightNo;
//         submitBtn.disabled = true;

//         setLoading(true);
//         try {
//             const response = await Rate_Chart_Service.updateExportAirWay(companyid, branchId, userId, selectednsdlStatus, items);
//             setItems(response.data);

//             const toastContent = `Airway bill number of SER <strong> ${response.data.serNo} </strong> has been updated`


//             await printBarcode(response.data.sbNo, response.data.serNo, response.data.noOfPackages, response.data.serDate, response.data.sbDate, "N", "1232");


//             toast.success(<div dangerouslySetInnerHTML={{ __html: toastContent }} />, {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 1200,
//                 style: { width: '35vw' },
//             });
//         } catch (error) {
//             console.error("Error in handleSubmit1 request:", error);
//             toast.error("An error occurred while updating the export airway. Please try again.", {
//                 autoClose: 1000,
//                 style: { width: '36vw' }
//             });
//         } finally {
//             setLoading(false);
//             submitBtn.disabled = false;
//         }
//     };


//     const handleFormSubmit = async (event) => {
//         event.preventDefault();
//         await getExportForAirWayBill(companyid, branchId, getlink);
//     };


//     const inputRef = useRef();

//     const handleKeyPress = (event) => {
//         if (event.key === 'Enter') {
//             event.preventDefault();
//             document.getElementById("submitButton").click();
//         }
//     };


//     const printBarcode = async (mawb, seino, nop, sirdate, reqdate, niptStatus, requestId) => {
//         try {
//             const response = await InviceService.getbarcode(mawb, seino, nop, sirdate, reqdate, "EXPORT", niptStatus, requestId);

//             // Check if the response status is OK (200)
//             if (response.status === 200) {
//                 // Get the raw response data as base64-encoded string
//                 const newWindow = window.open('', '_blank');
//                 newWindow.document.write(response.data);
//                 setTimeout(() => {
//                     newWindow.print(); // Open the print dialog
//                 }, 100);
//                 closeModal();
//             } else {
//                 throw new Error('Failed to generate PDF');
//             }
//         } catch (error) {
//         }
//     };


//     const handlePartyChange = async (selectedOption) => {
//         setSelectedParty(selectedOption ? selectedOption : null);
//         if (selectedOption) {
//             setItems(prevState => ({
//                 ...prevState,
//                 nameOfExporter: selectedOption ? selectedOption.value : '',
//                 partyName: selectedOption ? selectedOption.label : ''
//             }));
//             await getPartyByID(selectedOption ? selectedOption.value : '');
//         }
//         else {
//             setItems(prevState => ({
//                 ...prevState,
//                 nameOfExporter: '',
//                 iecCode: '',
//                 entityId: '',
//                 partyName: ''
//             }));
//         }
//         setErrors(prevErrors => ({
//             ...prevErrors,
//             party: '',
//         }));
//     };



//     const getPartyByID = async (partyId) => {
//         try {
//             const res = await Rate_Chart_Service.getPartyByIdNew(companyid, branchId, partyId, "EXP");
//             if (res.data) {
//                 const party = res.data;


//                 setItems(prevState => ({
//                     ...prevState,
//                     iecCode: party.iecNo || '',
//                     entityId: party.entityId || '',
//                     partyName: party.partyName || '',
//                     chaNo: party.status,
//                     consoleAgent: party.createdBy,
//                 }));


//             } else {
//                 console.log("No data received from getPartyById API");
//             }
//         } catch (error) {
//             console.error("Error fetching party data:", error);
//         }
//     };






//     const formatValue = (name, value) => {
//         const decimalFields = ['grossWeight', 'fobValueInINR'];

//         if (decimalFields.includes(name)) {
//             const match = value.match(/^\d*(\.\d{0,3})?/);
//             return match ? match[0] : '';
//         }
//         return value;
//     };




//     return (
//         <div className="Container" >

//             {loading && (
//                 <div style={styles.overlay}>
//                     <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//                 </div>
//             )}


//             <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-50px' }} > <FontAwesomeIcon
//                 icon={faBolt}
//                 style={{
//                     marginRight: '8px',
//                     color: 'black',
//                 }}
//             /> Generate AWB & SER New</h5>

//             <Card>
//                 <CardBody>
//                     <Form onSubmit={handleFormSubmit}>
//                         <Row>
//                             <Col md={4}>
//                                 <FormGroup>
//                                     <Label className="forlabel" for="search">Enter SbNo / Scan SER No</Label><span className='error'>*</span>
//                                     <Input
//                                         placeholder="Enter SbNo / Scan SER No"
//                                         type="text"
//                                         id="getlink"
//                                         value={getlink}
//                                         autoFocus
//                                         ref={inputRef}
//                                         onKeyDown={handleKeyPress}
//                                         onChange={(e) => setGetLink(e.target.value)}
//                                         className="inputField"
//                                     />
//                                 </FormGroup>
//                             </Col>
//                             <Col style={{ marginTop: 31, marginRight: 90, paddingRight: 90 }} md={2}>

//                                 <Button
//                                     className="allbutton"
//                                     variant="outline-success"
//                                     onClick={(e) => getExportForAirWayBill(companyid, branchId, getlink)}
//                                     id="submitButton"
//                                     style={{ marginRight: 5 , fontWeight: 'bold'}}
//                                 >
//                                     <FontAwesomeIcon icon={faSave} style={{ marginRight: "5px" }} />
//                                     Submit
//                                 </Button>
//                             </Col>
//                         </Row>
//                     </Form>
//                 </CardBody>
//             </Card>

//             <Modal Modal isOpen={isModalOpen} onClose={closeModal} toggle={closeModal} style={{ maxWidth: '800px', wioverflow: '-moz-hidden-unscrollable' }
//             } >
//                 <ModalHeader toggle={closeModal} style={{
//                     backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font', textAlign: 'center', background: '#26a69a',
//                     boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
//                     border: '1px solid rgba(0, 0, 0, 0.3)',
//                     borderRadius: '0',
//                     backgroundColor: '#85144b',
//                     backgroundColor: 'rgba(0, 0, 0, 0.3)',
//                     backgroundImage: 'url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
//                     backgroundSize: 'cover',
//                     backgroundRepeat: 'no-repeat',
//                     //backgroundPosition: 'center',
//                     backgroundPosition: 'center',
//                 }}>
//                     <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font' }} > <FontAwesomeIcon
//                         icon={faEdit}
//                         style={{
//                             marginRight: '8px',
//                             color: 'black', // Set the color to golden
//                         }}
//                     /> Update Airway Bill Number</h5>

//                 </ModalHeader>
//                 <ModalBody style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover' }}>
//                     <Row>
//                         <Col md={4}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Request ID</Label><span className='error'>*</span>
//                                 <Input
//                                     type="text"
//                                     name="challanNo"
//                                     maxLength={30}
//                                     readOnly
//                                     id="service"
//                                     required
//                                     tabIndex={-2}
//                                     value={items.sbRequestId}
//                                     className="inputField"

//                                 />
//                             </FormGroup>
//                         </Col>
//                         <Col md={4}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">SB Number</Label><span className='error'>*</span>
//                                 <Input
//                                     type="text"
//                                     name="challanNo"
//                                     id="service"
//                                     readOnly
//                                     maxLength={30}
//                                     requiretabIndex={-3}
//                                     value={items.sbNo}
//                                     className="inputField"

//                                 />
//                             </FormGroup>
//                         </Col>



//                         <Col md={4}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Ser Number</Label><span className='error'>*</span>
//                                 <Input
//                                     type="text"
//                                     name="serNo"
//                                     id="service"
//                                     readOnly
//                                     maxLength={30}
//                                     requiretabIndex={-3}
//                                     value={items.serNo}
//                                     className="inputField"

//                                 />
//                             </FormGroup>
//                         </Col>


//                         <Col md={4} >
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Gross Weight</Label><span className='error'>*</span>
//                                 <div style={{ position: 'relative' }}>
//                                     <Input
//                                         type="text" name="grossWeight"
//                                         className={errors.grossWeight ? 'error-border' : ''}
//                                         value={items.grossWeight}
//                                         placeholder="Enter Parcel's Gross Weight"
//                                         onChange={handleChange}
//                                         maxLength={8}
//                                     />
//                                     {errors.grossWeight && (
//                                         <div className="error-message">
//                                             {errors.grossWeight}
//                                         </div>
//                                     )}
//                                 </div>
//                             </FormGroup>
//                         </Col>

//                         <Col md={4}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Gross Weight Unit</Label>

//                                 <Input
//                                     type="select"
//                                     name="uomGrossWeight"
//                                     value={items.uomGrossWeight}
//                                     onChange={handleChange}
//                                 >
//                                     <option value="GRAMS">GRAMS</option>
//                                     <option value="KILO GRAMS">KILO GRAMS</option>
//                                 </Input>

//                             </FormGroup>
//                         </Col>

//                         <Col md={4}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Hold Status</Label>
//                                 <div style={{ position: 'relative' }}>
//                                     <Input
//                                         type="select"
//                                         name="holdStatus"
//                                         className={items.holdStatus === 'Y' ? 'error-border' : ''}
//                                         value={items.holdStatus}
//                                         onChange={handleChange}
//                                     >
//                                         <option value="Y">Yes</option>
//                                         <option value="N">No</option>
//                                     </Input>

//                                     {items.holdStatus === 'Y' && (
//                                         <div className="error-messageNew">
//                                             The Parcel Is Hold
//                                         </div>
//                                     )}
//                                 </div>
//                             </FormGroup>
//                         </Col>
//                     </Row>

//                     <Row>

//                         <Col md={2}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Nop</Label><span className='error'>*</span>
//                                 <Input
//                                     type="text"
//                                     name="challanNo"
//                                     id="service"
//                                     maxLength={30}
//                                     tabIndex={-3}
//                                     readOnly
//                                     required
//                                     value={items.noOfPackages}
//                                     className="inputField"

//                                 />
//                             </FormGroup>
//                         </Col>
//                         <Col md={6}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Select Party</Label><span className='error'>*</span>

//                                 <div style={{ position: 'relative' }}>
//                                     <Select
//                                         options={parties}
//                                         value={selectedParty}
//                                         onChange={handlePartyChange}
//                                         className={errors.party ? 'error-border' : ''}
//                                         placeholder="Select a Party"
//                                         isClearable
//                                         styles={{
//                                             control: (provided, state) => ({
//                                                 ...provided,
//                                                 border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                                                 boxShadow: 'none',
//                                                 '&:hover': {
//                                                     border: '1px solid #ccc'
//                                                 }
//                                             }),
//                                             indicatorSeparator: () => ({
//                                                 display: 'none'
//                                             }),
//                                             dropdownIndicator: () => ({
//                                                 display: 'none'
//                                             })
//                                         }}
//                                     />
//                                     {errors.party && (
//                                         <div className="error-message">
//                                             {errors.party}
//                                         </div>
//                                     )}
//                                 </div>
//                             </FormGroup>
//                         </Col>
//                         <Col md={4}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Airline</Label><span className='error'>*</span>
//                                 <select
//                                     id="airlineCode"
//                                     className="form-control"
//                                     name="airlineCode"
//                                     onChange={handleChange}
//                                     tabIndex={-5}
//                                     value={items.airlineCode}
//                                 >
//                                     <option value="">Select Airline</option>
//                                     {getAllFlight.map((data, index) => (
//                                         <option key={index} value={data.airlineCode}>{data.airlineName}</option>
//                                     ))}
//                                 </select>
//                             </FormGroup>
//                         </Col>


//                     </Row>


//                     <Row>
//                         <Col md={2}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Number</Label>
//                                 <Input
//                                     type="text"
//                                     name="airlineCode"
//                                     id="airlineCode"
//                                     maxLength={30}
//                                     tabIndex={-6}
//                                     required
//                                     value={items.airlineCode}
//                                     className="inputField"

//                                 />
//                             </FormGroup>
//                         </Col>
//                         <Col md={4}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Airway Bill Number</Label><span className='error'>*</span>
//                                 <Input
//                                     type="text"
//                                     name="airwayBillNo"
//                                     id="airwayBillNo"
//                                     tabIndex={-7}
//                                     maxLength={30}
//                                     required
//                                     onChange={handleChange}
//                                     value={items.airwayBillNo}
//                                     className="inputField"

//                                 />
//                             </FormGroup>
//                         </Col>
//                         <Col className="text-center" style={{ marginTop: 34 }} >
//                             <h4>OR</h4>
//                         </Col>
//                         <Col md={4}>
//                             <FormGroup>
//                                 <Label className="forlabel" for="branchId">Scan Airway Bill Number</Label>
//                                 <input
//                                     type="text"
//                                     onChange={(e) => setAirway(e.target.value)}
//                                     value={getAirway}
//                                     ref={inputRef1}
//                                     style={{ height: '38px', borderRadius: 4 }}
//                                 />
//                             </FormGroup>
//                         </Col>
//                     </Row>
//                     <Row>

//                         <Col className="text-center">
//                             {items.serNo && (
//                                 <Button onClick={() => { printBarcode(items.sbNo, items.serNo, items.noOfPackages, items.serDate, items.sbDate, "N", "1232") }} 
//                                 style={{ marginRight: 10 ,fontWeight: 'bold'}} variant="outline-primary">
//                                   <FontAwesomeIcon icon={faReceipt} style={{ marginRight: '5px' }} />   Print SER
//                                 </Button>
//                             )
//                             }

//                             <Button onClick={handleSubmit1} id="submitBtnnn"
//                                 variant="outline-success"
//                                 disabled={loading}
//                                 style={{ fontWeight: 'bold' }}>
//                                 <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
//                                 Submit
//                             </Button>
//                         </Col>
//                     </Row>
//                 </ModalBody>
//             </Modal>
//         </div>
//     );
// }






import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import "../Components/Style.css";
import ipaddress from "../Components/IpAddress";
import Dropdown from 'react-bootstrap/Dropdown';
import InviceService from "../services/InviceService"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";

import contachimage from "../services/contacts.png"
import DGDCimage from "../Images/DGDC.png";
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Table,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAtom, faBolt, faBoxesPacking, faHandHoldingHand, faHistory, faList, faList12, faListAlt, faPencil, faPlaneDeparture, faPlus, faPlusCircle, faReceipt, faSearch, faUserCircle, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCheck, faSave, faTimes, faSyncAlt, faCancel, faCog, faPrint, faXmark, faFileLines, faChessKing } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { toast } from "react-toastify";
import "../Components/Style.css";
import { Pagination } from "react-bootstrap";
import jsPDF from "jspdf";
import { Line, PDFDownloadLink } from "@react-pdf/renderer";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
    Image,
} from "@react-pdf/renderer";

import { BlobProvider } from "@react-pdf/renderer";
import { data } from "jquery";
import Rate_Chart_Service from "../services/Rate_Chart_Service";
import ReactLoading from 'react-loading';
import Select from 'react-select';


export default function GenerateSER() {

    const styles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Adjust the opacity and color as needed
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999, // Ensure the overlay is above other elements
        },
    };

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const {
        jwtToken,
        userId,
        username,
        branchId,
        companyid,
        role,
        companyname,
        branchname,
        login,
        logout,
    } = useContext(AuthContext);
    const [getAirway, setAirway] = useState('');
    const index1 = 3;
    // const firstPart = getAirway.slice(0, index1).replace(/[^0-9]/g, '');
    // const secondPart = getAirway.slice(index1).replace(/[^0-9]/g, '');
    const [flag, setFlag] = useState('N');
    const { isAuthenticated } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [nsdlStatusArray, setNsdlStatusArray] = useState([]);
    const [selectednsdlStatus, setSelectednsdlStatus] = useState('');
    const [parties, setPartiesAll] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);
    const inputRef1 = useRef();
    const [errors, setErrors] = useState({});




    // const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleAirwayChange = async (e) => {
        let inputValue = e.target.value;

        // console.log(" Before : "+e.target.value + " After : "+inputValue);

 // Remove all spaces if inputValue is not null or an empty string
 if (inputValue) {
    inputValue = inputValue.replace(/\s+/g, '');
}

console.log(" Before : "+e.target.value + " After : "+inputValue);

        setAirway(inputValue);

        const firstPart = inputValue.slice(0, index1).replace(/[^0-9]/g, '');
        const secondPart = inputValue.slice(index1).replace(/[^0-9]/g, '');

        if (inputValue.length >= 11) {
            items.airlineCode = firstPart;
            const airway = firstPart + secondPart;
            items.airwayBillNo = airway.slice(0, 11);
            setAirway('');
            setItems({ ...items });

            try {
                const data = await getSingleAirlineName();
                // await delay(1000); // Delay of 1 second
                await checkConditions(data); // Ensure checkConditions runs after getSingleAirlineName
            } catch (error) {
                console.error('Error in processing:', error);
            }
        }
    };
    const [saveairname, setSaveairname] = useState([]);
    const getSingleAirlineName = async () => {
        try {
            const response = await axios.get(`https://${ipaddress}Airline/find1/${companyid}/${branchId}/${items.airlineCode}`);
            setSaveairname(response.data); // Store the response data in state
            return response.data; // Return the response data
        } catch (error) {
            console.error("GET list error:", error);
        }
    };

 
    

    const checkConditions = async (airlineData) => {       
        if (            
            airlineData.airlineName &&
            items.airwayBillNo &&
            items.airwayBillNo.length >= 11
        ) {            
            await handleSubmit1(airlineData);
        }
    };




    // const findNsdlStatus = async () => {
    //     const PCKTYPEResponse = await Rate_Chart_Service.getJarByDtl(companyid, 'J00041');
    //     const partyOptions = PCKTYPEResponse.data.map(jar => ({
    //         value: jar[1],
    //         label: jar[1]
    //     }));
    //     setNsdlStatusArray(partyOptions);
    // };

    const hoverScanAWB = () => {
        setTimeout(() => {
            inputRef1.current.focus();
        }, 100
        );
    }

    const openModal = () => {
        setIsModalOpen(true);
        hoverScanAWB();
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setAirway('');
        setGetdata([]);
        setItems([]);
        setFlag('N');
        setSelectednsdlStatus('N');
    }





    // If the user is not authenticated, redirect to the login page
    useEffect(() => {
        if (!isAuthenticated) {
            navigate(
                "/login?message=You need to be authenticated to access this page."
            );
        }
    }, [isAuthenticated, navigate]);
    const [getlink, setGetLink] = useState("");
    const getScan = {
        companyId: companyid,
        branchId: branchId,
        createdBy: userId,
        editedBy: userId,
        approvedBy: userId,
        link: '',
        status: "A",
    }
    const [items, setItems] = useState([]);
    const [getdata, setGetdata] = useState([]);





    const getExportForAirWayBill = async (companyid, branchId, scanData) => {
        setLoading(true);
        try {
            const response = await Rate_Chart_Service.getExportForAirWay(companyid, branchId, scanData);
            // console.log(response.data);
            setItems(response.data);
            if (!response.data) {
                toast.error('Data not found!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 900,
                });
            }
            else {
                setSelectednsdlStatus(response.data.nsdlStatus);
                openModal();
            }
        } catch (error) {
            toast.error('Oops something went wrong!', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 900,
            });
            console.error("Error in getExportForAirWayBill request:", error);
        } finally {
            setGetLink('');
            setLoading(false);
        }

    };


    const findPartiesAll = async () => {

        const partyResponse = await Rate_Chart_Service.getAllActiveParties(companyid, branchId);

        const partyOptions = partyResponse.data.map(party => ({
            value: party[0],
            label: party[1]
        }));
        setPartiesAll(partyOptions);

    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        const formattedValue = formatValue(name, value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setItems((prevData) => ({
            ...prevData,
            [name]: formattedValue,
        }));


    }


    const [getAllFlight, setGetAllFlight] = useState([]);
    const getFlightlist = () => {
        axios
            .get(`https://${ipaddress}Airline/list/${companyid}/${branchId}`)
            .then((response) => {
                // console.log("GET list response:", response.data);
                setGetAllFlight(response.data); // Store the list in the state

            })
            .catch((error) => {
                console.error("GET list error:", error);
            });
    };

    useEffect(() => {
        findPartiesAll();
        getFlightlist();
    }, [companyid, branchId])

    

    useEffect(() => {
        getSingleAirlineName();
    }, [companyid, branchId, items.airlineName])

    const [saveaircodename, setSaveaircodename] = useState([]);
    const getSingleAirlineCode = () => {
        axios
            .get(`https://${ipaddress}Airline/findByCode/${companyid}/${branchId}/${saveairname.airlineCode}`)
            .then((response) => {
                // console.log("GET list response:", response.data);
                setSaveaircodename(response.data); // Store the list in the state

            })
            .catch((error) => {
                console.error("GET list error:", error);
            });
    };



    // useEffect(() => {
    //     getSingleAirlineCode();
    // }, [companyid, branchId, saveairname.airlineCode])

    const [getaircode, setGetaircode] = useState('');

    // useEffect(() => {
    //     setGetaircode(items.airlineCode)

    // }, [items.airlineCode])

    // useEffect(() => {
    //     if (getAirway.length >= 11) {
    //         const timer = setTimeout(() => {

    //             items.airlineCode = firstPart.replace(/[^0-9]/g, '');
    //             const airway = firstPart + secondPart.replace(/[^0-9]/g, '');
    //             items.airwayBillNo = airway.slice(0, 11);
    //             setAirway('');
    //             getSingleAirlineName();
    //         }, 100);

    //         return () => clearTimeout(timer);
    //     }
    // }, [getAirway]);


//     useEffect(() => {
//         if (
//             !items.serNo &&
//             saveairname.airlineName &&
//             items.airwayBillNo &&
//             items.airwayBillNo.length >= 11
//         ) {
// console.log("Calling To handleSubmit1();");

//             handleSubmit1();
//         }
//     }, [items, saveairname]);

    useEffect(() => {
        const foundType2 = parties.find(type => type.value === items.nameOfExporter);
        setSelectedParty(foundType2 ? foundType2 : null);
        if (foundType2) {
            setErrors(prevErrors => ({
                ...prevErrors,
                party: '',
            }));
        }

    }, [parties, items.nameOfExporter]);


    const handleSubmit1 = async (saveairname) => {
        // console.log("***************CALLING*************");

        if (saveairname.airlineName === '' || saveairname.airlineName === undefined || saveairname.airlineName === null) {
            toast.error("Please enter correct airway bill number.", {
                autoClose: 700
            });
            return;
        }
        if (items.airwayBillNo === '') {
            toast.error("Airway Bill No. is required", {
                autoClose: 700
            });
            return;
        } else {
            if (items.airwayBillNo.length < 11) {
                toast.error("Please enter an airway bill number greater than 11 digits.", {
                    autoClose: 700
                });
                return;
            }
        }

        const newErrors = {};
        if (!items.nameOfExporter) {
            newErrors.party = 'Select Party';
        }
        if (!items.grossWeight) {
            newErrors.grossWeight = 'Gross Weight is required';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {

            console.log(newErrors);
            return;
        }

        const submitBtn = document.getElementById("submitBtnnn");
        items.airlineName = saveairname.airlineName;
        items.flightNo = saveairname.flightNo;
        submitBtn.disabled = true;

        setLoading(true);
        try {
            const response = await Rate_Chart_Service.updateExportAirWay(companyid, branchId, userId, selectednsdlStatus, items);
            setItems(response.data);

            const toastContent = `Airway bill number of SER <strong> ${response.data.serNo} </strong> has been updated`


            await printBarcode(response.data.sbNo, response.data.serNo, response.data.noOfPackages, response.data.serDate, response.data.sbDate, "N", "1232");


            toast.success(<div dangerouslySetInnerHTML={{ __html: toastContent }} />, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1200,
                style: { width: '35vw' },
            });
        } catch (error) {
            console.error("Error in handleSubmit1 request:", error);
            toast.error("An error occurred while updating the export airway. Please try again.", {
                autoClose: 1000,
                style: { width: '36vw' }
            });
        } finally {
            setLoading(false);
            submitBtn.disabled = false;
        }
    };


    const handleFormSubmit = async (event) => {
        event.preventDefault();
        await getExportForAirWayBill(companyid, branchId, getlink);
    };


    const inputRef = useRef();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById("submitButton").click();
        }
    };


    const printBarcode = async (mawb, seino, nop, sirdate, reqdate, niptStatus, requestId) => {
        try {
            const response = await InviceService.getbarcode(mawb, seino, nop, sirdate, reqdate, "EXPORT", niptStatus, requestId);

            // Check if the response status is OK (200)
            if (response.status === 200) {
                // Get the raw response data as base64-encoded string
                const newWindow = window.open('', '_blank');
                newWindow.document.write(response.data);
                setTimeout(() => {
                    newWindow.print(); // Open the print dialog
                }, 100);
                closeModal();
            } else {
                throw new Error('Failed to generate PDF');
            }
        } catch (error) {
        }
    };


    const handlePartyChange = async (selectedOption) => {
        setSelectedParty(selectedOption ? selectedOption : null);
        if (selectedOption) {
            setItems(prevState => ({
                ...prevState,
                nameOfExporter: selectedOption ? selectedOption.value : '',
                partyName: selectedOption ? selectedOption.label : ''
            }));
            await getPartyByID(selectedOption ? selectedOption.value : '');
        }
        else {
            setItems(prevState => ({
                ...prevState,
                nameOfExporter: '',
                iecCode: '',
                entityId: '',
                partyName: ''
            }));
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            party: '',
        }));
    };



    const getPartyByID = async (partyId) => {
        try {
            const res = await Rate_Chart_Service.getPartyByIdNew(companyid, branchId, partyId, "EXP");
            if (res.data) {
                const party = res.data;


                setItems(prevState => ({
                    ...prevState,
                    iecCode: party.iecNo || '',
                    entityId: party.entityId || '',
                    partyName: party.partyName || '',
                    chaNo: party.status,
                    consoleAgent: party.createdBy,
                }));


            } else {
                console.log("No data received from getPartyById API");
            }
        } catch (error) {
            console.error("Error fetching party data:", error);
        }
    };






    const formatValue = (name, value) => {
        const decimalFields = ['grossWeight', 'fobValueInINR'];

        if (decimalFields.includes(name)) {
            const match = value.match(/^\d*(\.\d{0,3})?/);
            return match ? match[0] : '';
        }
        return value;
    };




    return (
        <div className="Container" >

            {loading && (
                <div style={styles.overlay}>
                    <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
                </div>
            )}


            <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-50px' }} > <FontAwesomeIcon
                icon={faBolt}
                style={{
                    marginRight: '8px',
                    color: 'black',
                }}
            /> Generate AWB & SER New</h5>

            <Card>
                <CardBody>
                    <Form onSubmit={handleFormSubmit}>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label className="forlabel" for="search">Enter SbNo / Scan SER No</Label><span className='error'>*</span>
                                    <Input
                                        placeholder="Enter SbNo / Scan SER No"
                                        type="text"
                                        id="getlink"
                                        value={getlink}
                                        autoFocus
                                        ref={inputRef}
                                        onKeyDown={handleKeyPress}
                                        onChange={(e) => setGetLink(e.target.value)}
                                        className="inputField"
                                    />
                                </FormGroup>
                            </Col>
                            <Col style={{ marginTop: 31, marginRight: 90, paddingRight: 90 }} md={2}>

                                <Button
                                    className="allbutton"
                                    variant="outline-success"
                                    onClick={(e) => getExportForAirWayBill(companyid, branchId, getlink)}
                                    id="submitButton"
                                    style={{ marginRight: 5 , fontWeight: 'bold'}}
                                >
                                    <FontAwesomeIcon icon={faSave} style={{ marginRight: "5px" }} />
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>

            <Modal Modal isOpen={isModalOpen} onClose={closeModal} toggle={closeModal} style={{ maxWidth: '800px', wioverflow: '-moz-hidden-unscrollable' }
            } >
                <ModalHeader toggle={closeModal} style={{
                    backgroundColor: '#80cbc4', color: 'black', fontFamily: 'Your-Heading-Font', textAlign: 'center', background: '#26a69a',
                    boxShadow: '0px 5px 10px rgba(0, 77, 64, 0.3)',
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    borderRadius: '0',
                    backgroundColor: '#85144b',
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    backgroundImage: 'url("https://images.pexels.com/photos/1103970/pexels-photo-1103970.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    //backgroundPosition: 'center',
                    backgroundPosition: 'center',
                }}>
                    <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font' }} > <FontAwesomeIcon
                        icon={faEdit}
                        style={{
                            marginRight: '8px',
                            color: 'black', // Set the color to golden
                        }}
                    /> Update Airway Bill Number</h5>

                </ModalHeader>
                <ModalBody style={{ backgroundImage: 'url(https://img.freepik.com/free-vector/gradient-wavy-background_23-2149123392.jpg?t=st=1694859409~exp=1694860009~hmac=b397945a9c2d45405ac64956165f76bd10a0eff99334c52cd4c88d4162aad58e)', backgroundSize: 'cover' }}>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Request ID</Label><span className='error'>*</span>
                                <Input
                                    type="text"
                                    name="challanNo"
                                    maxLength={30}
                                    readOnly
                                    id="service"
                                    required
                                    tabIndex={-2}
                                    value={items.sbRequestId}
                                    className="inputField"

                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">SB Number</Label><span className='error'>*</span>
                                <Input
                                    type="text"
                                    name="challanNo"
                                    id="service"
                                    readOnly
                                    maxLength={30}
                                    requiretabIndex={-3}
                                    value={items.sbNo}
                                    className="inputField"

                                />
                            </FormGroup>
                        </Col>



                        <Col md={4}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Ser Number</Label><span className='error'>*</span>
                                <Input
                                    type="text"
                                    name="serNo"
                                    id="service"
                                    readOnly
                                    maxLength={30}
                                    requiretabIndex={-3}
                                    value={items.serNo}
                                    className="inputField"

                                />
                            </FormGroup>
                        </Col>


                        <Col md={4} >
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Gross Weight</Label><span className='error'>*</span>
                                <div style={{ position: 'relative' }}>
                                    <Input
                                        type="text" name="grossWeight"
                                        className={errors.grossWeight ? 'error-border' : ''}
                                        value={items.grossWeight}
                                        placeholder="Enter Parcel's Gross Weight"
                                        onChange={handleChange}
                                        maxLength={8}
                                    />
                                    {errors.grossWeight && (
                                        <div className="error-message">
                                            {errors.grossWeight}
                                        </div>
                                    )}
                                </div>
                            </FormGroup>
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Gross Weight Unit</Label>

                                <Input
                                    type="select"
                                    name="uomGrossWeight"
                                    value={items.uomGrossWeight}
                                    onChange={handleChange}
                                >
                                    <option value="GRAMS">GRAMS</option>
                                    <option value="KILO GRAMS">KILO GRAMS</option>
                                </Input>

                            </FormGroup>
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Hold Status</Label>
                                <div style={{ position: 'relative' }}>
                                    <Input
                                        type="select"
                                        name="holdStatus"
                                        className={items.holdStatus === 'Y' ? 'error-border' : ''}
                                        value={items.holdStatus}
                                        onChange={handleChange}
                                    >
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                    </Input>

                                    {items.holdStatus === 'Y' && (
                                        <div className="error-messageNew">
                                            The Parcel Is Hold
                                        </div>
                                    )}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>

                        <Col md={2}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Nop</Label><span className='error'>*</span>
                                <Input
                                    type="text"
                                    name="challanNo"
                                    id="service"
                                    maxLength={30}
                                    tabIndex={-3}
                                    readOnly
                                    required
                                    value={items.noOfPackages}
                                    className="inputField"

                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Select Party</Label><span className='error'>*</span>

                                <div style={{ position: 'relative' }}>
                                    <Select
                                        options={parties}
                                        value={selectedParty}
                                        onChange={handlePartyChange}
                                        className={errors.party ? 'error-border' : ''}
                                        placeholder="Select a Party"
                                        isClearable
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    border: '1px solid #ccc'
                                                }
                                            }),
                                            indicatorSeparator: () => ({
                                                display: 'none'
                                            }),
                                            dropdownIndicator: () => ({
                                                display: 'none'
                                            })
                                        }}
                                    />
                                    {errors.party && (
                                        <div className="error-message">
                                            {errors.party}
                                        </div>
                                    )}
                                </div>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Airline</Label><span className='error'>*</span>
                                <select
                                    id="airlineCode"
                                    className="form-control"
                                    name="airlineCode"
                                    onChange={handleChange}
                                    tabIndex={-5}
                                    value={items.airlineCode}
                                >
                                    <option value="">Select Airline</option>
                                    {getAllFlight.map((data, index) => (
                                        <option key={index} value={data.airlineCode}>{data.airlineName}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>


                    </Row>


                    <Row>
                        <Col md={2}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Number</Label>
                                <Input
                                    type="text"
                                    name="airlineCode"
                                    id="airlineCode"
                                    maxLength={30}
                                    tabIndex={-6}
                                    required
                                    value={items.airlineCode}
                                    className="inputField"

                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Airway Bill Number</Label><span className='error'>*</span>
                                <Input
                                    type="text"
                                    name="airwayBillNo"
                                    id="airwayBillNo"
                                    tabIndex={-7}
                                    maxLength={30}
                                    required
                                    onChange={handleChange}
                                    value={items.airwayBillNo}
                                    className="inputField"

                                />
                            </FormGroup>
                        </Col>
                        <Col className="text-center" style={{ marginTop: 34 }} >
                            <h4>OR</h4>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label className="forlabel" for="branchId">Scan Airway Bill Number</Label>
                                <input
                                    type="text"
                                    // onChange={(e) => setAirway(e.target.value)}
                                    onChange={handleAirwayChange}
                                    value={getAirway}
                                    ref={inputRef1}
                                    style={{ height: '38px', borderRadius: 4 }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>

                        <Col className="text-center">
                            {items.serNo && (
                                <Button onClick={() => { printBarcode(items.sbNo, items.serNo, items.noOfPackages, items.serDate, items.sbDate, "N", "1232") }} 
                                style={{ marginRight: 10 ,fontWeight: 'bold'}} variant="outline-primary">
                                  <FontAwesomeIcon icon={faReceipt} style={{ marginRight: '5px' }} />   Print SER
                                </Button>
                            )
                            }

                            <Button onClick={() => handleSubmit1(saveairname)} id="submitBtnnn"
                                variant="outline-success"
                                disabled={loading}
                                style={{ fontWeight: 'bold' }}>
                                <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
}