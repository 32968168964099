// // import AuthContext from "../Components/AuthProvider";
// // import { useNavigate } from "react-router-dom";
// // import React, { useEffect, useState, useContext, useRef } from "react";
// // import "../Components/Style.css";
// // import Row from "react-bootstrap/Row";
// // import Col from "react-bootstrap/Col";
// // import Button from "react-bootstrap/Button";
// // import Form from "react-bootstrap/Form";
// // import { toast } from "react-toastify";
// // import ipaddress from "../Components/IpAddress";
// // import Card from "react-bootstrap/Card";
// // import { CardBody, Input } from "reactstrap";
// // import DGDCimage from "../Images/DGDC.png";
// // import { saveAs } from "file-saver";
// // import axios from "axios";
// // import dgdcImage from "../Images/report.jpeg";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import DatePicker from "react-datepicker";
// // import "react-datepicker/dist/react-datepicker.css"; // Import the styles
// // import { faArrowsToEye, faBorderAll, faEye, faFileArrowUp, faSearch } from "@fortawesome/free-solid-svg-icons";
// // import {
// //   faCheck,
// //   faSave,
// //   faTimes,
// //   faSyncAlt,
// //   faFileExcel,
// //   faFilePdf,
// //   faPrint,
// // } from "@fortawesome/free-solid-svg-icons";
// // import { Table } from "react-bootstrap";
// // import * as XLSX from "xlsx";
// // import jsPDF from "jspdf";
// // import { Line, pdf, PDFDownloadLink } from "@react-pdf/renderer";
// // import {
// //   Document,
// //   Page,
// //   Text,
// //   View,
// //   StyleSheet,
// //   PDFViewer,
// //   Image,
// // } from "@react-pdf/renderer";
// // import { BlobProvider } from "@react-pdf/renderer";
// // import { data } from "jquery";
// // const styles = StyleSheet.create({
// //   page: {
// //     paddingTop: 18,
// //     paddingBottom: 54,
// //     paddingHorizontal: 30,
// //   },
// //   header: {
// //     marginBottom: 5,
// //   },
// //   heading: {
// //     fontSize: 9,
// //     marginBottom: 0,
// //     fontWeight: "bold",
// //     alignItems: "center",
// //   },
// //   table: {
// //     width: "100%",
// //     borderCollapse: "collapse",
// //     marginBottom: 4,
// //   },
// //   centeredText: {
// //     fontSize: 13,
// //     textAlign: "center", // Center text horizontally
// //   },
// //   leftColumn: {
// //     width: "100%",
// //     paddingTop: 9,
// //   },
// //   headingwithbox: {
// //     fontSize: 9,
// //     marginBottom: 0,
// //     fontWeight: "bold",
// //     alignItems: "center",

// //     // Add padding for space between text and border
// //   },
// //   headerText: {
// //     fontSize: 9,
// //     fontWeight: "bold",
// //   },
// //   viewheadingwithbox: {
// //     border: "1px solid black",
// //     padding: 5,
// //   },
// //   paragraph: {
// //     fontSize: 12,
// //     marginBottom: 5,
// //   },
// //   headingwithborder: {
// //     fontSize: 9,
// //     marginBottom: 0,
// //     fontWeight: "bold",
// //     alignItems: "center",
// //     borderBottom: "1px solid black",
// //     // Add padding for space between text and border
// //   },
// //   image: {
// //     width: 306,
// //     height: 100,
// //     marginLeft: 117,
// //     justifyContent: "center",
// //   },
// //   dateSize: {
// //     fontSize: 8,
// //   },
// //   normaltext: {
// //     fontSize: 9,
// //     marginTop: 4,
// //     fontWeight: "bold",
// //   },
// //   line: {
// //     width: "100%", // Adjust the width of the line
// //     marginTop: 10, // Adjust the space above the line
// //     marginBottom: 10, // Adjust the space below the line
// //     borderTop: "1pt solid black", // Style the line
// //   },
// //   rightText: {
// //     fontSize: 9,
// //     textAlign: "left", // Center text horizontally
// //     paddingTop:5,
// //   },
// //   tableRow: {
// //     flexDirection: "row",
// //     borderBottomWidth: 0.4,
// //     borderBottomColor: "black",
// //     fontSize: 9,
// //     borderRightWidth: 0.4,
// //     borderLeftWidth: 0.4,
// //     borderLeftColor: "black",
// //     borderRightColor: "black",
// //     flexWrap: "wrap",
// //   },
// //   tableCell: {
// //     border: "0.4px solid black",
// //     padding: 1,
// //     fontSize:7,
// //     flexWrap: "wrap",
// //     width: 73,
// //     textAlign:'center'
// //   },
// //   tableCellHeader: {
// //     fontWeight: "bold",
// //     flexWrap: "wrap",
// //     width: 73,
// //     textAlign:'center',
// //     border: "0.4px solid black",
// //   },
// // });

// // export default function ExportTp() {
// //   const navigate = useNavigate();

// //   const {
// //     jwtToken,
// //     userId,
// //     username,
// //     branchId,
// //     companyid,
// //     role,
// //     companyname,
// //     branchname,
// //     isAuthenticated,
// //     login,
// //     logout,
// //   } = useContext(AuthContext);

// //   const formatTpNo = (tpNo) => {
// //     // Remove leading zeros using a regular expression
// //     return tpNo.replace(/^0+/, '');
// //   };

// //   const CustomHeader = () => {
// //     return (
// //       <View style={styles.header}>
// //         <Image src={dgdcImage} style={styles.image} />
// //       </View>
// //     );
// //   };
// //   const today = new Date().toISOString().split("T")[0];

// //   // State to store selected date
// //   const currentDate = new Date();
// //   const currentDateString = currentDate.toISOString().split("T")[0];

// //   const [selectedDate, setSelectedDate] = useState(new Date());
// //   console.log("date2 ", selectedDate);
// //   const [pdfData, setPdfData] = useState(null);
// //   // State to store the Transhipment Permit No (replace with actual data)
// //   const [transhipmentPermitNo, setTranshipmentPermitNo] =
// //     useState(currentDateString);
// //   const [generatedPDF, setGeneratedPDF] = useState(null);
// //   const [showPdfModal, setShowPdfModal] = useState(false);
// //   const [responseData, setResponseData] = useState([]);
// //   const [tpdata, setTpdata] = useState([]);
// //   const [error, setError] = useState(null);

// //   const totalRows = tpdata.length;
// //   const [vehicleNo, setVehicleNo] = useState("");
// //   const [Custodian, setCustodian] = useState("Sunil Patil");

// //   const formatedDate = (inputDate) => {
// //     const date = new Date(inputDate);
// //     const year = date.getFullYear();
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const day = String(date.getDate()).padStart(2, "0");
// //     return `${day}/${month}/${year}`;
// //   };

// //   const [partys, setPartys] = useState([]);
// //   const [getpartyId, setGetpartyId] = useState({});
// //   const fetchPartyNames = async () => {
// //     try {
// //       const response = await fetch(
// //         `https://${ipaddress}parties/getAll/${companyid}/${branchId}`
// //       );
// //       const data = await response.json();
// //       const namesMap = {};
// //       data.forEach((party) => {
// //         namesMap[party.partyId] = party.partyName;
// //       });
// //       setGetpartyId(namesMap);
// //       setPartys(data);
// //     } catch (error) {
// //       console.error("Error fetching party names:", error);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchPartyNames();
// //   }, []);

// //   // If the user is not authenticated, redirect to the login page
// //   useEffect(() => {
// //     if (!isAuthenticated) {
// //       navigate(
// //         "/login?message=You need to be authenticated to access this page."
// //       );
// //     }
// //   }, [isAuthenticated, navigate]);

// //   const formatDate = (date) => {
// //     const formattedDate = new Date(date).toISOString().split("T")[0];
// //     return formattedDate;
// //   };

// //   const handleDateChange = (date) => {
// //     setSelectedDate(date);
// //     const formattedDate = formatDate(date);

// //     // Replace this with your actual API call logic
// //     fetch(
// //       `https://${ipaddress}export/tpNo?date=${formattedDate}&cid=${companyid}&bid=${branchId}`
// //     )
// //       .then((response) => response.json())
// //       .then((data) => {
// //         // Assuming data is an array containing Transhipment Permit No
// //         if (data && data.length > 0) {
// //           setResponseData(data); // Update responseData with fetched data
// //           console.log("dataaa ", data); // Log the updated data
// //         } else {
// //           setResponseData([]); // Update responseData to an empty array if no data available
// //         }
// //       })
// //       .catch((error) => {
// //         console.error("Error fetching data:", error);
// //       });
// //   };

// //   useEffect(()=>{
// //     handleDateChange(new Date())
// //   },[companyid,branchId])

// //   const handleTPDateChange = async (date) => {
// //     setSelectedDate(date);
// //     const formattedDate = formatDate(date);

// //     try {
// //       const response = await fetch(
// //         `https://${ipaddress}export/getalldata?cid=${companyid}&bid=${branchId}&date=${formattedDate}&tpno=${transhipmentPermitNo}`
// //       );
// //       const data = await response.json();
// //       if (data && data.length > 0) {
// //         setTpdata(data);
// //         console.log("dataaa ", data);
// //         console.log(tpdata);
// //       } else {
// //         setTpdata([]);
// //       }

// //       setError(null); // Clear the error if data is successfully fetched
// //     } catch (error) {
// //       console.error("Error fetching data:", error);
// //       setError(error); // Set the error state if there's an error
// //     }
// //   };
  

// //   const handleTranshipmentPermitNoChange = (value) => {
// //     setTranshipmentPermitNo(value);
// //     handleTPDateChange(selectedDate);
// //   };

// //   const handleShowButtonClick = () => {
// //     if (!selectedDate) {
// //       // Show an alert if the date is not selected
// //       alert("Please select a Transhipment Permit Date.");
// //       return;
// //     }

// //     handleTPDateChange(selectedDate);
// //   };

// //   const handleResetButtonClick = () => {
// //     setSelectedDate(new Date());
// //     setTranshipmentPermitNo("");
// //     setTpdata([]);
// //     setResponseData([]);
// //     setVehicleNo('');
// //     setCustodian("Sunil Patil")
// //     handleDateChange(new Date());
// //   };

// //   const [currentDateTime, setCurrentDateTime] = useState("");

// //   useEffect(() => {
// //     const interval = setInterval(() => {
// //       const now = new Date();
// //       const options = {
// //         year: "2-digit", // '2-digit' for short year format
// //         month: "2-digit", // '2-digit' for leading zeros
// //         day: "2-digit", // '2-digit' for leading zeros
// //         hour: "2-digit", // '2-digit' for leading zeros
// //         minute: "2-digit", // '2-digit' for leading zeros
// //       };
// //       const formattedDateTime = now.toLocaleString("en-US", options);
// //       setCurrentDateTime(formattedDateTime);
// //     }, 1000); // Update every second

// //     return () => clearInterval(interval);
// //   }, []);

// //   // console.log("date ", currentDateTime);

// //   function fetchCompanyName(companyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(`https://${ipaddress}export/findCompanyname/${companyId}`)
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }

// //   function fetchBranchName(companyId, branchId) {
// //     // Make an Axios GET request to retrieve the branch name based on branchId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findBranchName/${companyId}/${branchId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved branch name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching branch name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }
// //   function fetchPartyName(companyId, branchId, partyId) {
// //     // Make an Axios GET request to retrieve the company name based on companyId
// //     return axios
// //       .get(
// //         `https://${ipaddress}export/findPartyName/${companyId}/${branchId}/${partyId}`
// //       )
// //       .then(function (response) {
// //         return response.data; // Return the retrieved company name
// //       })
// //       .catch(function (error) {
// //         console.error("Error fetching company name:", error);
// //         return ""; // Return an empty string or handle the error as needed
// //       });
// //   }
// //   const handleXLSDownload = async () => {
// //     const modifiedRecordList = await Promise.all(
// //       tpdata.map(async (item, index) => {
// //         // const companyname = await fetchCompanyName(item.companyId);
// //         // const branchname = await fetchBranchName(item.companyId, item.branchId);
// //         const partyName = await fetchPartyName(
// //           item.companyId,
// //           item.branchId,
// //           item.nameOfExporter
// //         );
// //         return {
// //           "Sr.No": index + 1,
// //           // "Company Name": companyname,
// //           // "Branch Name": branchname,
// //           "SER No": item.serNo,
// //           "SER Date": formatedDate(item.serDate),
// //           "Parcle Type": " ", 
// //           "NAME OF THE Exporter ": getpartyId[item.nameOfExporter],
// //           "NO OF PKGS": item.noOfPackages,
// //           "DESC": item.descriptionOfGoods,
// //           "VALUE IN RS": item.fobValueInINR,
// //           "PORT OF DESTINATION": item.portOfDestination,
// //         };
// //       })
// //     );
  
// //     const distanceRow = {
// //       "Sr.No": "",
// //       // "Company Name": "",
// //       // "Branch Name": "",
// //       "SER NO": "",
// //       "SER DATE": "",
// //       "PARCEL TYPE": "",
// //       "NAME OF THE Exporter ": "",
// //       "NO OF PKGS": "",
// //       "DESC": "",
// //       "VALUE IN RS": "",
// //       "PORT OF DESTINATION": "",
// //     };
   
// //     const workbook = XLSX.utils.book_new();
// //     const worksheet = XLSX.utils.json_to_sheet([
// //       ...modifiedRecordList,
    
// //     ]);
  
// //     // Add headers for all fields
// //     const headers = Object.keys(modifiedRecordList[0]);
// //     headers.forEach((header, index) => {
// //       worksheet[XLSX.utils.encode_cell({ r: 0, c: index })] = {
// //         t: "s",
// //         v: header,
// //         s: { font: { bold: true } },
// //       };
// //     });
  
// //     // Set column widths based on data
// //     const colWidths = headers.map((header) => ({
// //       wch: header.length + 2, // You can adjust the width as needed
// //     }));
  
// //     worksheet["!cols"] = colWidths;
  
// //     XLSX.utils.book_append_sheet(workbook, worksheet, "Export_Tp");
// //     const xlsFile = XLSX.write(workbook, { type: "binary", bookType: "xls" });
// //     const blob = new Blob([s2ab(xlsFile)], {
// //       type: "application/vnd.ms-excel",
// //     });
  
// //     saveAs(blob, "Export_Tp.xls");
// //   };
// //   const s2ab = (s) => {
// //     const buf = new ArrayBuffer(s.length);
// //     const view = new Uint8Array(buf);
// //     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
// //     return buf;
// //   };
// //   const PAGE_BREAK_ROWS = 30; // Adjust this based on how many rows fit on one page

// //   const PAGE_BREAK_ROWS_PDF = 18;
// //   const handlePDFDownload = async () => {
// //     const pdfBlob = await pdf(
// //       <MyPDFDocument {...{ totalNoOfPackages, tpdata }} />
// //     ).toBlob();
// //     saveAs(pdfBlob, "Export_TP.pdf");
// //   };
// //   const MyPDFDocument = ({ totalNoOfPackages, tpdata }) => (
// //     <Document>
// //       {Array.from({
// //         length: Math.ceil(tpdata.length / PAGE_BREAK_ROWS_PDF),
// //       }).map((_, pageIndex) => (
// //         <Page key={pageIndex} size="A4" style={styles.page}>
// //           <CustomHeader />

// //           <View style={styles.hr}></View>
// //           <View style={[styles.centeredText, { fontWeight: "bold" }]}>
// //             <Text style={[styles.centeredText, { fontWeight: "bold" }]}>
// //               EXPORT TP
// //             </Text>
// //           </View>

// //           <View>
// //             <Text style={[styles.centeredText, { fontSize: 10 }]}>
// //               Seepz Transhipment Permit No. : {formatTpNo(tpdata[0].tpNo)}
// //               {"\n"}
// //             </Text>
// //           </View>
// //           <View
// //             style={{ flexDirection: "row", paddingTop: 9, paddingBottom: 5 }}
// //           >
// //             <View style={{ width: "80%" }}>
// //               <Text style={{ fontSize: 9 }}>
// //                 To,
// //                 {"\n"}
// //                 DGDC LIMITED
// //               </Text>
// //             </View>
// //             <View style={{ width: "20%" }}>
// //               <Text style={{ fontSize: 9 }}>
// //                 Date : {formatedDate(tpdata[0].tpDate)}
// //                 {"\n"}
// //               </Text>
// //               <Text style={{ fontSize: 9 }}>
// //                 Page No : {pageIndex + 1}
// //                 {"\n"}
// //               </Text>
// //             </View>
// //           </View>
// //           <View>
// //             <Text style={[styles.headerText, { paddingBottom: 4 }]}>
// //               Strong Room : International Air Cargo Complex Sahar, Mumbai - 400
// //               096
// //             </Text>
// //           </View>

// //           <View style={styles.table}>
// //             {" "}
// //             <View style={styles.tableRow}>
// //               <Text
// //                 style={[
// //                   styles.tableCell,
// //                   styles.tableCellHeader,
// //                   { fontSize: 9, width: 32 },
// //                 ]}
// //               >
// //                 Sl.No.
// //               </Text>
// //               <Text
// //                 style={[
// //                   styles.tableCell,
// //                   styles.tableCellHeader,
// //                   { fontSize: 9, width: 54 },
// //                 ]}
// //               >
// //                 SER No
// //               </Text>
// //               <Text
// //                 style={[
// //                   styles.tableCell,
// //                   styles.tableCellHeader,
// //                   { fontSize: 9 ,width:54},
// //                 ]}
// //               >
// //                 SER Date
// //               </Text>
// //               <Text
// //                 style={[
// //                   styles.tableCell,
// //                   styles.tableCellHeader,
// //                   { fontSize: 9, width: 159 },
// //                 ]}
// //               >
// //                 Name Of Exporter
// //               </Text>
// //               <Text
// //                 style={[
// //                   styles.tableCell,
// //                   styles.tableCellHeader,
// //                   { fontSize: 9 ,width:45},
// //                 ]}
// //               >
// //                 NOP
// //               </Text>
// //               <Text
// //                 style={[
// //                   styles.tableCell,
// //                   styles.tableCellHeader,
// //                   { fontSize: 9 ,width:82},
// //                 ]}
// //               >
// //                 Description
// //               </Text>
// //               <Text
// //                 style={[
// //                   styles.tableCell,
// //                   styles.tableCellHeader,
// //                   { fontSize: 9,width:54 },
// //                 ]}
// //               >
// //                 Value Rs.
// //               </Text>
// //               <Text
// //                 style={[
// //                   styles.tableCell,
// //                   styles.tableCellHeader,
// //                   { fontSize: 9 ,width:54},
// //                 ]}
// //               >
// //                 Destination
// //               </Text>
// //             </View>
// //             {tpdata
// //               .slice(
// //                 pageIndex * PAGE_BREAK_ROWS_PDF,
// //                 (pageIndex + 1) * PAGE_BREAK_ROWS_PDF
// //               )
// //               .map((item, index) => (
// //                 <View style={styles.tableRow} key={index}>
// //                   <Text style={{ ...styles.tableCell, width: 32 }}>
// //                     {index + 1 + pageIndex * PAGE_BREAK_ROWS_PDF}
// //                   </Text>
// //                   <Text style={{ ...styles.tableCell, width: 54 }}>
// //                     {item.serNo}
// //                   </Text>
// //                   <Text style={{ ...styles.tableCell,width:54 }}>
// //                     {formatedDate(item.serDate)}
// //                   </Text>
// //                   <Text style={{ ...styles.tableCell, width: 159 }}>
// //                     {getpartyId[item.nameOfExporter]}
// //                   </Text>
// //                   <Text style={{ ...styles.tableCell ,width:45}}>
// //                     {item.noOfPackages}
// //                   </Text>
// //                   <Text style={{ ...styles.tableCell ,width:82 }}>
// //                     {item.descriptionOfGoods}
// //                   </Text>
// //                   <Text style={{ ...styles.tableCell,width:54 }}>
// //                     {item.fobValueInINR}
// //                   </Text>
// //                   <Text style={{ ...styles.tableCell,width:54 }}>
// //                     {item.portOfDestination}
// //                   </Text>
// //                 </View>
// //               ))}
// //           </View>

// //           {/* Display the "Total" row only on the last page */}
// //           {pageIndex === Math.ceil(tpdata.length / PAGE_BREAK_ROWS_PDF) - 1 && (
// //             <View style={styles.tableRow}>
// //               <Text style={{ ...styles.tableCell, width: 32 }}>Total</Text>
// //               <Text style={{ ...styles.tableCell, width: 54 }}></Text>
// //               <Text
// //                 style={[
// //                   styles.tableCell,
// //                   styles.tableCellHeader,
// //                   { fontSize: 9 ,width:54},
// //                 ]}
// //               ></Text>
// //               <Text style={{ ...styles.tableCell, width: 159 }}></Text>

// //               <Text style={{ ...styles.tableCell,width:45 }}>
// //                 {totalNoOfPackages} &nbsp;({numberToWords(totalNoOfPackages)})
// //               </Text>

// //               <Text style={{ ...styles.tableCell ,width:82 }}></Text>

// //               <Text style={{ ...styles.tableCell,width:54 }}>
// //                 {" "}
// //                 {totalFobValueInINR} &nbsp;({numberToWords(totalFobValueInINR)})
// //               </Text>
// //               <Text style={{ ...styles.tableCell,width:54 }}></Text>
// //             </View>
// //           )}
// //           <View>
// //             <Text style={{ fontSize: 9, marginTop: 5 }}>
// //               Vehicle No : {vehicleNo}
// //             </Text>
// //           </View>

// //           <View>
// //             <Text style={styles.rightText}>
// //               {"\n\n"}(Signature of Custodian)
// //             </Text>
// //           </View>
// //         </Page>
// //       ))}
// //     </Document>
// //   );


// //   const handlePrint = async () => {
// //     const pdfBlob = await pdf(
// //       <MyPDFDocument {...{ totalNoOfPackages, tpdata }} />
// //     ).toBlob();
  
// //     const url = URL.createObjectURL(pdfBlob);
// //     const newTab = window.open('about:blank');
    
// //     if (newTab) {
// //       newTab.location.href = url;
// //     } else {
// //       alert('Please allow pop-ups for this site to open the PDF.');
// //     }
// //   };
  
// //   const [totalFobValueInINR, setTotalFobValueInINR] = useState(0);



// //   // const handlePrint = () => {
// //   //   const isoDate = new Date().toISOString();
// //   //   const date = new Date(isoDate);
// //   //   const day = date.getDate().toString().padStart(2, "0");
// //   //   const month = (date.getMonth() + 1).toString().padStart(2, "0");
// //   //   const year = date.getFullYear().toString();
  
// //   //   const printWindow = window.open("", "_blank");
// //   //   printWindow.document.open();
  
// //   //   const recordsPerPage = 100;
// //   //   const totalRecords = tpdata.length;
// //   //   let displayIndex = 0;
// //   //   for (
// //   //     let pageIndex = 0;
// //   //     pageIndex < Math.ceil(totalRecords / recordsPerPage);
// //   //     pageIndex++
// //   //   ) {
// //   //     printWindow.document.write(`
// //   //       <!DOCTYPE html>
// //   //       <html>
// //   //       <head>    
// //   //       <title> Export TP Report</title>
  
// //   //         <style>
// //   //           @page {
// //   //             margin: 1cm;
// //   //           }
  
// //   //           .printable-area {
// //   //             font-family: Arial, sans-serif;
// //   //             page-break-before: always;
// //   //           }
  
// //   //           table {
// //   //             width: 100%;
// //   //             border-collapse: collapse;
// //   //             margin-bottom: 20px;
// //   //           }
  
// //   //           th, td {
// //   //             border: 1px solid #dddddd;
// //   //             text-align: center;
// //   //             padding: 5px;
// //   //             font-size: 12px;
// //   //           }
  
// //   //           th {
// //   //             background-color: #f2f2f2;
// //   //           }
  
// //   //           .container {
// //   //             display: flex;
// //   //             justify-content: space-between;
// //   //           }
  
// //   //           .left-column {
// //   //             width: 70%;
// //   //             font-weight: bold;
// //   //             font-size: 12px;
// //   //           }
  
// //   //           .right-column {
// //   //             width: 30%;
// //   //             text-align: right;
// //   //             font-weight: bold;
// //   //             font-size: 12px;
// //   //           }
  
// //   //           .header img {
// //   //             max-width: auto;
// //   //             max-height: auto;
// //   //             display: block;
// //   //             margin: 0 auto;
// //   //           }
  
// //   //           #page-header {
// //   //             text-align: center;
// //   //           }
// //   //         </style>
// //   //       </head>
// //   //       <body>
// //   //         <div id="page-header">
// //   //           <img src="${dgdcImage}" alt="Header Image" style="max-width: 306px;"> 
// //   //           <div style="font-size: 12px; font-weight: bold;">EXPORT TP</br>
// //   //             Seepz Transhipment permit No.:${formatTpNo(tpdata[0].tpNo)}
// //   //           </div>
// //   //         </div>
  
// //   //         <div class="content">
// //   //           <div class="container">
// //   //             <div class="left-column">
// //   //               To,</br>
// //   //               DGDC LIMITED
// //   //             </div>
// //   //             <div class="right-column">
// //   //               Date:${formatedDate(tpdata[0].tpDate)}</br>
// //   //               Page No:${pageIndex + 1}
// //   //             </div>
// //   //           </div>
// //   //           <div style="font-weight: bold; font-size: 12px;"> Strong Room : International Air Cargo Complex Sahar, Mumbai - 400 096</div>
// //   //           <table>
// //   //             <thead>
// //   //               <tr>
// //   //                 <th>Sr.No</th>
// //   //                 <th>SER No</th>
// //   //                 <th>SER Date</th>
// //   //                 <th>Name Of Exporter</th>
// //   //                 <th>No Of Pkgs</th>
// //   //                 <th>Description</th>
// //   //                 <th>Values Rs.</th>
// //   //                 <th>Destination</th>
// //   //               </tr>
// //   //             </thead>
// //   //             <tbody>
// //   //     `);
  
// //   //     const startRecordIndex = pageIndex * recordsPerPage;
// //   //     for (let i = startRecordIndex; i < Math.min(startRecordIndex + recordsPerPage, totalRecords); i++) {
// //   //       const item = tpdata[i];
// //   //       displayIndex++;
  
// //   //       printWindow.document.write(`
// //   //         <tr>
// //   //           <td>${displayIndex}</td>
// //   //           <td>${item.serNo || ""}</td>
// //   //           <td>${formatedDate(item.serDate) || ""}</td>
// //   //           <td>${getpartyId[item.nameOfExporter] || ""}</td>
// //   //           <td>${item.noOfPackages || ""}</td>
// //   //           <td>${item.descriptionOfGoods || ""}</td>
// //   //           <td>${item.fobValueInINR || ""}</td>
// //   //           <td>${item.portOfDestination || ""}</td>
// //   //         </tr>
// //   //       `);
// //   //     }
  
// //   //     printWindow.document.write(`
// //   //             </tbody>
// //   //           </table>
// //   //         </div>
// //   //       </body>
// //   //       </html>
// //   //     `);
  
// //   //     if (pageIndex < Math.ceil(totalRecords / recordsPerPage) - 1) {
// //   //       printWindow.document.write('<div class="printable-area"></div>');
// //   //     }
// //   //   }
  
// //   //   printWindow.document.close();
// //   //   printWindow.print();
// //   //   printWindow.onafterprint = () => printWindow.close();
// //   // };
  


// //   function numberToWords(number) {
// //     const words = [
// //       "",
// //       "One",
// //       "Two",
// //       "Three",
// //       "Four",
// //       "Five",
// //       "Six",
// //       "Seven",
// //       "Eight",
// //       "Nine",
// //       "Ten",
// //       "Eleven",
// //       "Twelve",
// //       "Thirteen",
// //       "Fourteen",
// //       "Fifteen",
// //       "Sixteen",
// //       "Seventeen",
// //       "Eighteen",
// //       "Nineteen",
// //     ];
  
// //     const tensWords = [
// //       "",
// //       "",
// //       "Twenty",
// //       "Thirty",
// //       "Forty",
// //       "Fifty",
// //       "Sixty",
// //       "Seventy",
// //       "Eighty",
// //       "Ninety",
// //     ];
  
// //     const scales = ["", "Thousand", "Lakh", "Crore"];
  
// //     if (number === 0) return "Zero";
// //     if (number < 0) return "Minus " + numberToWords(-number);
  
// //     let wordsString = '';
  
// //     for (let i = 0; number > 0; i++) {
// //       if (number % 1000 !== 0) {
// //         const word = numberToWordsLessThanThousand(number % 1000);
// //         wordsString = word + ' ' + scales[i] + ' ' + wordsString;
// //       }
// //       number = Math.floor(number / 1000);
// //     }
  
// //     return wordsString.trim();
// //   }
  
// //   function numberToWordsLessThanThousand(number) {
// //     const words = [
// //       "",
// //       "One",
// //       "Two",
// //       "Three",
// //       "Four",
// //       "Five",
// //       "Six",
// //       "Seven",
// //       "Eight",
// //       "Nine",
// //       "Ten",
// //       "Eleven",
// //       "Twelve",
// //       "Thirteen",
// //       "Fourteen",
// //       "Fifteen",
// //       "Sixteen",
// //       "Seventeen",
// //       "Eighteen",
// //       "Nineteen",
// //     ];
  
// //     const tensWords = [
// //       "",
// //       "",
// //       "Twenty",
// //       "Thirty",
// //       "Forty",
// //       "Fifty",
// //       "Sixty",
// //       "Seventy",
// //       "Eighty",
// //       "Ninety",
// //     ];
  
// //     let wordsString = '';
  
// //     if (number > 99) {
// //       wordsString += words[Math.floor(number / 100)] + " Hundred ";
// //       number %= 100;
// //     }
  
// //     if (number > 19) {
// //       wordsString += tensWords[Math.floor(number / 10)];
// //       number %= 10;
// //     }
  
// //     if (number > 0) {
// //       wordsString += words[number];
// //     }
  
// //     return wordsString;
// //   }
  

// //   // Example usage:
// //   console.log(numberToWords(10000000)); // Outputs: "Ten Million"

// //   function getCurrentDateTimeFormatted() {
// //     const currentDate = new Date();

// //     const day = currentDate.getDate();
// //     const month = currentDate.getMonth() + 1;
// //     const year = currentDate.getFullYear() % 100; // Get the last two digits of the year
// //     const hours = currentDate.getHours();
// //     const minutes = currentDate.getMinutes();

// //     const period = hours >= 12 ? "PM" : "AM";
// //     const formattedHours = hours % 12 || 12; // Convert to 12-hour format
// //     const formattedMinutes = String(minutes).padStart(2, "0");

// //     const formattedDateTime = `${month}/${day}/${year}, ${formattedHours}:${formattedMinutes} ${period}`;

// //     return formattedDateTime;
// //   }

// //   const [pdfBlob, setPdfBlob] = useState(null);
  


// //   const [totalNoOfPackages, setTotalNoOfPackages] = useState(0);
// //   // const [totalFobValueInINR, setTotalFobValueInINR] = useState(0);

// //   // Calculate totals when the tpdata changes
// //   useEffect(() => {
// //     let packagesTotal = 0;
// //     let fobValueTotal = 0;

// //     tpdata.forEach((item) => {
// //       packagesTotal += item[3];
// //       fobValueTotal += item[5];
// //     });

// //     setTotalNoOfPackages(packagesTotal);
// //     setTotalFobValueInINR(fobValueTotal);
// //   }, [tpdata]);



// //   //Export TP

// //  const formatDateTime2 = (value) => {
// //   if (!value) {
// //     return "";
// //   }
// //   const date = new Date(value);
// //   const day = String(date.getDate()).padStart(2, "0");
// //   const month = String(date.getMonth() + 1).padStart(2, "0");
// //   const year = date.getFullYear();
// //   const hours = String(date.getHours()).padStart(2, "0");
// //   const minutes = String(date.getMinutes()).padStart(2, "0");
// //   const seconds = String(date.getSeconds()).padStart(2, "0");
// //   return `${day}/${month}/${year} `;
// // };
// // const getExcel = (imp) => {
// //   const filename = `Export_TP_${formatDateTime2(new Date())}.xlsx`; // Note: Changed file extension to xlsx
// //   axios.post(`https://${ipaddress}export/tpexcel/${companyid}/${branchId}`, imp, { responseType: 'blob' }) // Added responseType: 'blob'
// //     .then(async (response) => {
// //       const blob = new Blob([response.data], { type: response.headers['content-type'] });

// //       // Create a temporary URL for the blob
// //       const url = window.URL.createObjectURL(blob);

// //       // Create a link element to trigger the download
// //       const a = document.createElement("a");
// //       a.href = url;
// //       a.download = filename;
// //       document.body.appendChild(a);
// //       a.click();

// //       // Clean up
// //       window.URL.revokeObjectURL(url);
// //       document.body.removeChild(a);
// //     })
// //     .catch((error) => {
// //       toast.error("Something went wrong", {
// //         autoClose: 700
// //       });
// //     });
// //   }


// //   const tpPdf = async(data,date,tp,vehicle) =>{
// //     const formattedDate = formatDate(date);
// //     const params = {
// //       companyId: companyid,
// //       branchId: branchId,
// //       tpdate: formattedDate,
// //       tpno: tp,
// //       vehicle: vehicle,
// //       custodian: Custodian    
// //     };
  
// //     await axios.post(`https://${ipaddress}export/exportTPReport`,data,{ params })
// //     .then((response)=>{
// //       const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
// //         // Create a Blob from the Base64 data
// //         const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
// //         // Create a URL for the Blob
// //         const blobUrl = URL.createObjectURL(pdfBlob);
// //         // Create an anchor element for downloading
// //         const downloadLink = document.createElement('a');
// //         downloadLink.href = blobUrl;
// //         downloadLink.download = 'ExportTP.pdf'; // Set the filename for the downloaded PDF
// //         downloadLink.style.display = 'none';
// //         document.body.appendChild(downloadLink);
// //         // Trigger the download
// //         downloadLink.click();
// //         // Clean up
// //         document.body.removeChild(downloadLink);
// //         window.URL.revokeObjectURL(blobUrl);
// //         toast.success("Downloading Pdf!", {
// //             position: toast.POSITION.TOP_CENTER,
// //             autoClose: 800,
// //         });
// //       })
// //       .catch((error)=>{
// //         if(error){
// //           toast.error("Something went wrong",{
// //             autoClose:1000
// //           })
// //         }
// //       })      
// //   }


// //   const tpPrint = async(data,date,tp,vehicle) =>{
// //     const formattedDate = formatDate(date);
// //     console.log("pctmdata ",data)


// //     const params = {
// //       companyId: companyid,
// //       branchId: branchId,
// //       tpdate: formattedDate,
// //       tpno: tp,
// //       vehicle: vehicle,
// //       custodian: Custodian    
// //     };
  
// //     await axios.post(`https://${ipaddress}export/exportTPReport`,data,{ params })
// //     .then((response)=>{
// //       const base64PDF = response.data;

// //       // Create a new window for displaying the PDF
// //       const newWindow = window.open("", "_blank");

// //       // Write the HTML content to the new window
// //       newWindow.document.write(`
// //       <!DOCTYPE html>
// //       <html>
// //       <head>
// //         <title>Export TP</title>
// //         <style>
// //           body {
// //             margin: 0;
// //             padding: 0;
// //             overflow: hidden;
// //           }
// //           embed {
// //             width: 100vw;
// //             height: 100vh;
// //           }
// //         </style>
// //       </head>
// //       <body>
// //         <embed src="data:application/pdf;base64,${base64PDF}" type="application/pdf" width="100%" height="100%">
// //       </body>
// //       </html>
// //     `);
// //     })
// //     .catch((error)=>{
// //       if(error){
// //         toast.error("Something went wrong",{
// //           autoClose:1000
// //         })
// //       }
// //     })      
// //   }



// //   return (
// //     <div className="Container" >
// //       <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }} > <FontAwesomeIcon
// //         icon={faFileArrowUp}
// //         style={{
// //           marginRight: '8px',
// //           color: 'black', // Set the color to golden
// //         }}
// //       />Export TP Reports</h5>
// //       <Card >
// //         <CardBody>
// //           <Form>
// //             <Row>
// //               <Col md={3} >
// //                 <Form.Group>
// //                   <Form.Label className="forlabel">
// //                     Transhipment Permit Date
// //                   </Form.Label>

// //                   <DatePicker
// //                     selected={selectedDate}
// //                     wrapperClassName="custom-react-datepicker-wrapper"
// //                     onChange={handleDateChange}
// //                     value={selectedDate}
// //                     dateFormat="dd/MM/yyyy"
// //                     className="form-control"
// //                     customInput={<input style={{ width: '100%' }} />}
// //                   />

// //                   {/* <Form.Control
// //                     type="date"
// //                     max={today}
// //                     value={selectedDate}
// //                     onChange={(e) => handleDateChange(e.target.value)}
// //                     placeholder=" "
// //                   /> */}




// //                 </Form.Group>
// //               </Col>
// //               <Col md={3}>
// //                 <Form.Group>
// //                   <Form.Label className="forlabel">Transhipment Permit No</Form.Label>
// //                   <select
// //                     name="company"
// //                     className="form-select"
// //                     value={transhipmentPermitNo}
// //                     onChange={(e) => handleTranshipmentPermitNoChange(e.target.value)}
// //                   >
// //                     {responseData.length > 0 && <option>Select</option>}
// //                     {responseData.length > 0 &&
// //                       responseData.map((item) => {
// //                         // Remove leading zeros and display the number
// //                         const formattedTpNo = item.replace(/^0+/, ''); // Removes leading zeros
// //                         return (
// //                           <option key={item} value={item}>
// //                             {formattedTpNo}
// //                           </option>
// //                         );
// //                       })}
// //                   </select>
// //                 </Form.Group>
// //               </Col>

// //               <Col md={3} >
// //                 <Form.Group>
// //                   <Form.Label className="forlabel">
// //                     Vehicle No
// //                   </Form.Label>

// //                   {/* <label className="forlabel">Vehicle No</label> */}
// //                   <Input
// //                     type="text"
// //                     name="vehicleNo"
// //                     placeholder="Enter Vehicle Number"
// //                     className=""
// //                     value={vehicleNo}
// //                     onChange={(e) => setVehicleNo(e.target.value)}
// //                   />
// //                 </Form.Group>
// //               </Col>
// //               <Col md={3} >
// //                 <Form.Group>
// //                   <Form.Label className="forlabel">
// //                     Custodian
// //                   </Form.Label>

// //                   {/* <label className="forlabel">Vehicle No</label> */}
// //                   <Input
// //                     type="text"
// //                     name="Custodian"
// //                     placeholder="Enter Custodian Name"
// //                     className=""
// //                     value={Custodian}
// //                     onChange={(e) => setCustodian(e.target.value)}
// //                   />
// //                 </Form.Group>
// //               </Col>

             
// //             </Row>

// //             <div className="d-flex justify-content-center align-items-center">
// //                 <div style={{ marginTop: 18 }}>
// //                   <Button
// //                     onClick={handleShowButtonClick}
// //                     variant="outline-primary"
// //                     style={{ marginRight: 20 }}
// //                   >
// //                     <FontAwesomeIcon
// //                       icon={faArrowsToEye}
// //                       style={{ marginRight: "5px" }}
// //                     />
// //                     Show
// //                   </Button>
// //                   <Button
// //                     onClick={handleResetButtonClick}
// //                     variant="outline-danger"
// //                   >
// //                     <FontAwesomeIcon
// //                       icon={faSyncAlt}
// //                       style={{ marginRight: "5px" }}
// //                     />
// //                     Reset
// //                   </Button>
// //                 </div>
// //               </div>


// //           </Form>
// //           <hr />
// //           <handlePDFDownload2 tpdata={tpdata} />

// //           <div hidden={!tpdata.length > 0}>
// //             <Row>
// //               <div style={{ float: "right", marginBottom: 9 }}>
// //                 <button
// //                   style={{ marginLeft: 9 }}
// //                   className="btn btn-outline-primary btn-margin"
// //                   type="button"
// //                   onClick={()=>tpPrint(tpdata,selectedDate,transhipmentPermitNo,vehicleNo)}
// //                 >
// //                   <FontAwesomeIcon
// //                     icon={faPrint}
// //                     style={{ marginRight: "5px" }}
// //                   />
// //                   Print
// //                 </button>
// //                 <button
// //                   className="btn btn-outline-primary btn-margin"
// //                   type="button"
// //                   onClick={()=>tpPdf(tpdata,selectedDate,transhipmentPermitNo,vehicleNo)}
// //                   style={{ marginLeft: "10px" }}
// //                 >
// //                   <FontAwesomeIcon
// //                     icon={faFilePdf}
// //                     style={{ marginRight: "5px" }}
// //                   />
// //                   PDF
// //                 </button>
// //                 <button
// //                   className="btn btn-outline-success btn-margin"
// //                   type="button"
// //                   style={{ marginLeft: "10px", marginRight: 9 }}
// //                   onClick={()=>getExcel(tpdata)}
// //                 >
// //                   <FontAwesomeIcon
// //                     icon={faFileExcel}
// //                     style={{ marginRight: "5px" }}
// //                   />
// //                   XLS
// //                 </button>
// //               </div>
// //             </Row>

// //             <CardBody >
// //               <div className="table-responsive" >
// //                 <Table className="table table-bordered text-center custom-table mt-3">
// //                   <thead>
// //                     <tr>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>SL No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Parcel Type</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Exporter Name</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>No of Pkg</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Description</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Value Rs.</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Destination</th>
// //                     </tr>
// //                   </thead>
// //                   <thead>
// //                     <tr>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}> </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}> </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}> </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}> </th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>{totalNoOfPackages}</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}>{totalFobValueInINR}</th>
// //                       <th style={{ backgroundColor: "#BADDDA" }}></th>
// //                     </tr>
// //                   </thead>
// //                   <tbody>
// //                     {tpdata.map((item, index) => (
// //                       <tr key={index}>
// //                         <td>{index + 1}</td>
// //                         <td>{item[0]}</td>
// //                         <td>{formatedDate(item[1])}</td>
// //                         <td>{item[7] === 'Y'?'SC':item[8] === 'Y' ? 'P':item[9]==='Y'?'HW':''}</td>
// //                         <td>{item[2]}</td>
// //                         <td>{item[3]}</td>
// //                         <td>{item[4]}</td>
// //                         <td>{item[5]}</td>
// //                         <td>{item[6]}</td>
// //                       </tr>
// //                     ))}
// //                   </tbody>
// //                 </Table>
// //               </div>
// //             </CardBody>
// //             <div style={{ display: "flex" }}>
// //               <div style={{ flex: "25%", padding: "10px" }}>
// //                 <strong>Total No of Packages:</strong> {totalNoOfPackages}
// //               </div>
// //               <div style={{ flex: "25%", padding: "10px" }}>
// //                 <strong>Total Value Rs.=:</strong> {totalFobValueInINR}
// //               </div>
// //             </div>
// //           </div>
// //         </CardBody>
// //       </Card>
// //     </div>
// //   );
// // }










// import AuthContext from "../Components/AuthProvider";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState, useContext, useRef } from "react";
// import "../Components/Style.css";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import { toast } from "react-toastify";
// import ipaddress from "../Components/IpAddress";
// import {
//   Card,
//   CardBody,
//   FormGroup,
//   Label,
//   Input
// } from "reactstrap";
// import { Pagination } from "react-bootstrap";
// import { saveAs } from "file-saver";
// import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css"; // Import the styles
// import { faArrowsToEye, faBorderAll, faEye, faFileArrowUp, faSearch } from "@fortawesome/free-solid-svg-icons";
// import {
//   faCheck,
//   faSave,
//   faTimes,
//   faSyncAlt,
//   faFileExcel,
//   faFilePdf,
//   faPrint,
// } from "@fortawesome/free-solid-svg-icons";
// import { Table } from "react-bootstrap";
// import moment from 'moment';
// import Select from 'react-select';
// import ReactLoading from 'react-loading';
// import Rate_Chart_Service from "../services/Rate_Chart_Service";

// export default function ExportTp() {
//   const navigate = useNavigate();

//   const {
//     jwtToken,
//     userId,
//     username,
//     branchId,
//     companyid,
//     role,
//     companyname,
//     branchname,
//     isAuthenticated,
//     login,
//     logout,
//   } = useContext(AuthContext);




//   const [loading, setLoading] = useState(false);
//   const styles = {
//     overlay: {
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       backgroundColor: 'rgba(255, 255, 255, 0.8)',
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       zIndex: 9999,
//     },
//   };


//   const [tpdata, setTpdata] = useState([]);
//   const [errors, setErrors] = useState({});


//   const formatedDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const year = date.getFullYear();
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const day = String(date.getDate()).padStart(2, "0");
//     return `${day}/${month}/${year}`;
//   };

//   const [partys, setPartys] = useState([]);
//   const [getpartyId, setGetpartyId] = useState({});


//   // If the user is not authenticated, redirect to the login page
//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate(
//         "/login?message=You need to be authenticated to access this page."
//       );
//     }
//   }, [isAuthenticated, navigate]);

//   const [consoleArray, setConsoleArray] = useState([])
//   const [selectedConsole, setSelectedConsole] = useState(null);
//   const [tpNoArray, setTpNoArray] = useState([]);
//   const [selectedTpNo, setSelectedTpNo] = useState(null);



//   const formatDate = (date) => {
//     if (!date) {
//       date = new Date();
//     }
//     return moment(date).format('YYYY-MM-DD');
//   };


//   const initialSearchCriteria = {
//     companyId: companyid,
//     branchId: branchId,
//     tpNo: '',
//     startDate: formatDate(new Date()),
//     endDate: formatDate(new Date()),
//     vehicleNo: '',
//     Custodian: 'Sunil Patil',
//     console: '',
//     userType: 'console'
//   };
//   const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);



//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setSearchCriteria((prevState) => ({
//       ...prevState,
//       [name]: value
//     }));
//   };


//   const findTpNumbers = async () => {
//     setLoading(true);
//     setTpNoArray([]);
//     setSelectedTpNo(null);
//     setTpdata([]);
//     try {
//       const resultSet = await Rate_Chart_Service.searchTp({ params: searchCriteria });
//       setTpNoArray(resultSet.data);
//     } catch (error) {
//       console.error("Error occurred while fetching data:", error);
//     }
//     finally {
//       setLoading(false);
//     }
//   }



//   const findConsoles = async () => {
//     setLoading(true);
//     setConsoleArray([]);
//     setSelectedConsole(null);
//     try {
//       const partyOptions = await Rate_Chart_Service.searchConsoleForExportTp({ params: searchCriteria })
//       setConsoleArray(partyOptions.data);
//     } catch (error) {
//       console.error("Error occurred while fetching data:", error);
//     }
//     finally {
//       setLoading(false);
//     }
//   }



//   // Effect to call findTpNumbers when tpDate changes
//   useEffect(() => {
//     findTpNumbers();
//   }, [searchCriteria.startDate]); // Dependency array

//   useEffect(() => {
//     findConsoles();
//   }, [searchCriteria.tpNo, searchCriteria.startDate]); // Dependency array



//   // Handle change for date input
//   const handleDateChange = (date) => {
//     setSearchCriteria((prevState) => ({
//       ...prevState,
//       startDate: formatDate(date),
//       endDate: formatDate(date)
//     }));


//     if (errors.startDate) {
//       const updatedErrors = { ...errors };
//       delete updatedErrors.startDate;
//       setErrors(updatedErrors);
//     }
//   };




//   // const handleDateChange = (date) => {
//   //   setSelectedDate(date);
//   //   const formattedDate = formatDate(date);

//   //   // Replace this with your actual API call logic
//   //   fetch(
//   //     `https://${ipaddress}export/tpNo?date=${formattedDate}&cid=${companyid}&bid=${branchId}`
//   //   )
//   //     .then((response) => response.json())
//   //     .then((data) => {
//   //       // Assuming data is an array containing Transhipment Permit No
//   //       if (data && data.length > 0) {
//   //         setResponseData(data); // Update responseData with fetched data
//   //         console.log("dataaa ", data); // Log the updated data
//   //       } else {
//   //         setResponseData([]); // Update responseData to an empty array if no data available
//   //       }
//   //     })
//   //     .catch((error) => {
//   //       console.error("Error fetching data:", error);
//   //     });
//   // };

//   // useEffect(() => {
//   //   handleDateChange(new Date())
//   // }, [companyid, branchId])

//   const handleSearch = async (searchCriteria) => {

//     const error = {};
//     // Validate startDate
//     if (!searchCriteria.startDate) {
//       error.startDate = "Start Date is required";
//     }
//     // Validate tpNo
//     if (!searchCriteria.tpNo) {
//       error.tpNo = "TP No is required";
//     }
//     // Check if any errors exist
//     if (Object.keys(error).length > 0) {

//       toast.error("Select required fields", {
//         position: toast.POSITION.TOP_CENTER,
//         autoClose: 1200,
//       });
//       setErrors(error);
//       // Return early if there are errors
//       return;
//     }


//     setCurrentPage(1)
//     setLoading(true);
//     try {
//       const response = await Rate_Chart_Service.searchExportTpData({ params: searchCriteria });
//       const data = response.data;
//       if (data && data.length > 0) {
//         setTpdata(data);
//         console.log("tp dataaa \n", data);
//       } else {
//         setTpdata([]);
//       }
//       // setError([]);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       // setError(error); // Set the error state if there's an error
//     } finally {
//       setLoading(false);
//     }
//   };


//   const handleResetButtonClick = async () => {
//     setSearchCriteria(initialSearchCriteria);
//     setTpdata([]);
//     setSelectedTpNo(null);
//     setSelectedConsole(null);
//     // await handleSearch(initialSearchCriteria);
//     setErrors([]);
//   };









//   function numberToWordsLessThanThousand(number) {
//     const words = [
//       "",
//       "One",
//       "Two",
//       "Three",
//       "Four",
//       "Five",
//       "Six",
//       "Seven",
//       "Eight",
//       "Nine",
//       "Ten",
//       "Eleven",
//       "Twelve",
//       "Thirteen",
//       "Fourteen",
//       "Fifteen",
//       "Sixteen",
//       "Seventeen",
//       "Eighteen",
//       "Nineteen",
//     ];

//     const tensWords = [
//       "",
//       "",
//       "Twenty",
//       "Thirty",
//       "Forty",
//       "Fifty",
//       "Sixty",
//       "Seventy",
//       "Eighty",
//       "Ninety",
//     ];

//     let wordsString = '';

//     if (number > 99) {
//       wordsString += words[Math.floor(number / 100)] + " Hundred ";
//       number %= 100;
//     }

//     if (number > 19) {
//       wordsString += tensWords[Math.floor(number / 10)];
//       number %= 10;
//     }

//     if (number > 0) {
//       wordsString += words[number];
//     }

//     return wordsString;
//   }




//   function getCurrentDateTimeFormatted() {
//     const currentDate = new Date();

//     const day = currentDate.getDate();
//     const month = currentDate.getMonth() + 1;
//     const year = currentDate.getFullYear() % 100; // Get the last two digits of the year
//     const hours = currentDate.getHours();
//     const minutes = currentDate.getMinutes();

//     const period = hours >= 12 ? "PM" : "AM";
//     const formattedHours = hours % 12 || 12; // Convert to 12-hour format
//     const formattedMinutes = String(minutes).padStart(2, "0");

//     const formattedDateTime = `${month}/${day}/${year}, ${formattedHours}:${formattedMinutes} ${period}`;

//     return formattedDateTime;
//   }



//   //Export TP

//   const formatDateTime2 = (value) => {
//     if (!value) {
//       return "";
//     }
//     const date = new Date(value);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}/${month}/${year} `;
//   };
//   const getExcel = async (imp) => {
//     const filename = `Export_TP_${formatDateTime2(new Date())}.xlsx`;
//     setLoading(true);
//     try {
//       const response = await axios.post(`https://${ipaddress}export/tpexcel/${companyid}/${branchId}`, imp, { responseType: 'blob' });

//       const blob = new Blob([response.data], { type: response.headers['content-type'] });

//       // Create a temporary URL for the blob
//       const url = window.URL.createObjectURL(blob);

//       // Create a link element to trigger the download
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = filename;
//       document.body.appendChild(a);
//       a.click();

//       // Clean up
//       window.URL.revokeObjectURL(url);
//       document.body.removeChild(a);
//     } catch (error) {
//       toast.error("Something went wrong", {
//         autoClose: 700
//       });
//     } finally {
//       setLoading(false);
//     }
//   };


//   // const tpPdf = async (data, date, tp, vehicle) => {
//   //   const formattedDate = formatDate(date);
//   //   const params = {
//   //     companyId: companyid,
//   //     branchId: branchId,
//   //     tpdate: formattedDate,
//   //     tpno: tp,
//   //     vehicle: vehicle,
//   //     custodian: Custodian
//   //   };

//   //   await axios.post(`https://${ipaddress}export/exportTPReport`, data, { params })
//   //     .then((response) => {
//   //       const pdfBase64 = response.data; // Assuming response.data contains the Base64-encoded PDF
//   //       // Create a Blob from the Base64 data
//   //       const pdfBlob = new Blob([Uint8Array.from(atob(pdfBase64), c => c.charCodeAt(0))], { type: 'application/pdf' });
//   //       // Create a URL for the Blob
//   //       const blobUrl = URL.createObjectURL(pdfBlob);
//   //       // Create an anchor element for downloading
//   //       const downloadLink = document.createElement('a');
//   //       downloadLink.href = blobUrl;
//   //       downloadLink.download = 'ExportTP.pdf'; // Set the filename for the downloaded PDF
//   //       downloadLink.style.display = 'none';
//   //       document.body.appendChild(downloadLink);
//   //       // Trigger the download
//   //       downloadLink.click();
//   //       // Clean up
//   //       document.body.removeChild(downloadLink);
//   //       window.URL.revokeObjectURL(blobUrl);
//   //       toast.success("Downloading Pdf!", {
//   //         position: toast.POSITION.TOP_CENTER,
//   //         autoClose: 800,
//   //       });
//   //     })
//   //     .catch((error) => {
//   //       if (error) {
//   //         toast.error("Something went wrong", {
//   //           autoClose: 1000
//   //         })
//   //       }
//   //     })
//   // }


//   const handlePrint = async (type) => {
//     setLoading(true);
//     try {
//       const response = await Rate_Chart_Service.generateExportTpPrint(searchCriteria, tpdata);

//       if (response.status === 200) {
//         const pdfData = response.data;
//         const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
//         const blobUrl = URL.createObjectURL(pdfBlob);

//         if (type === "PDF") {
//           // Create an anchor element for downloading
//           const downloadLink = document.createElement('a');
//           downloadLink.href = blobUrl;
//           downloadLink.download = 'Export_Tp.pdf';
//           downloadLink.style.display = 'none';
//           document.body.appendChild(downloadLink);
//           // Trigger the download
//           downloadLink.click();
//           // Clean up
//           document.body.removeChild(downloadLink);
//           window.URL.revokeObjectURL(blobUrl);
//         }
//         if (type === 'PRINT') {
//           window.open(blobUrl, '_blank');
//         }
//         toast.success("Downloading Pdf!", {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: 800,
//         });
//       } else {
//         toast.error("error downLoading file!", {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: 800,
//         });
//       }
//     } catch (error) {
//       console.error("Error downloading PDF:", error);
//     }
//     finally {
//       setLoading(false);
//     }
//   };


//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(30);



//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = tpdata.slice(indexOfFirstItem, indexOfLastItem);
//   const totalPages = Math.ceil(tpdata.length / itemsPerPage);

//   // Function to handle page change
//   const handlePageChange = (page) => {
//     if (page >= 1 && page <= totalPages) {
//       setCurrentPage(page);
//     }
//   };
//   const displayPages = () => {
//     const centerPageCount = 5;
//     const middlePage = Math.floor(centerPageCount / 2);
//     let startPage = currentPage - middlePage;
//     let endPage = currentPage + middlePage;

//     if (startPage < 1) {
//       startPage = 1;
//       endPage = Math.min(totalPages, centerPageCount);
//     }

//     if (endPage > totalPages) {
//       endPage = totalPages;
//       startPage = Math.max(1, totalPages - centerPageCount + 1);
//     }

//     return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
//   };

//   // Function to calculate the total for a specific column
//   const calculateTotal = (data, columnIndex) => {
//     const total = data.reduce((sum, row) => sum + (parseFloat(row[columnIndex]) || 0), 0);
//     // Check if the total has a fractional part
//     const roundedTotal = total % 1 !== 0 ? parseFloat(total.toFixed(2)) : total;

//     return roundedTotal !== 0 ? roundedTotal : ''; // Return the rounded total or an empty string if zero
//   };

//   const handleConsoleChange = selectedOption => {
//     setSelectedConsole(selectedOption);
//     setSearchCriteria({ ...searchCriteria, console: selectedOption ? selectedOption.value : '' });
//     setTpdata([]);
//   };

//   const handleTpNoChange = async selectedOption => {
//     setSearchCriteria({ ...searchCriteria, console: '' });
//     setConsoleArray([]);
//     setSelectedConsole(null);
//     setSelectedTpNo(selectedOption);
//     setSearchCriteria({ ...searchCriteria, tpNo: selectedOption ? selectedOption.value : '' });
//     if (errors.tpNo) {
//       const updatedErrors = { ...errors };
//       delete updatedErrors.tpNo;
//       setErrors(updatedErrors);
//     }
//   };




//   return (

//     <>
//       {loading && (
//         <div style={styles.overlay}>
//           <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
//         </div>
//       )}
//       <div className="Container" >




//         <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }} > <FontAwesomeIcon
//           icon={faFileArrowUp}
//           style={{
//             marginRight: '8px',
//             color: 'black', // Set the color to golden
//           }}
//         />Export TP Reports</h5>
//         <Card >
//           <CardBody>
//             <Form>
//               <Row>
//                 <Col md={2}>
//                   <FormGroup>
//                     <Label className="forlabel" for="branchId">Tp Date</Label><span className='error'>*</span>

//                     <DatePicker
//                       selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
//                       wrapperClassName="custom-react-datepicker-wrapper"
//                       onChange={handleDateChange}
//                       dateFormat="dd/MM/yyyy" // Specify the combined format
//                       className={`form-control border-right-0 inputField ${errors.startDate ? 'error-border' : ''}`}
//                       customInput={<input style={{ width: '100%' }} />}
//                     />

//                   </FormGroup>
//                 </Col>
//                 <Col md={2}>
//                   <FormGroup>
//                     <Label className="forlabel" for="typeOfProduct">Select Tp No</Label><span className='error'>*</span>

//                     <Select
//                       options={tpNoArray}
//                       value={selectedTpNo}
//                       onChange={handleTpNoChange}
//                       isClearable
//                       className={errors.tpNo ? 'error-border' : ''}
//                       placeholder="Select Tp No"
//                       styles={{
//                         control: (provided, state) => ({
//                           ...provided,
//                           border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                           // borderColor: errors.consoleName ? '#f52b2b' : '',
//                           boxShadow: 'none',
//                           '&:hover': {
//                             border: '1px solid #ccc'
//                           }
//                         }),
//                         indicatorSeparator: () => ({
//                           display: 'none'
//                         }),
//                         dropdownIndicator: () => ({
//                           display: 'none'
//                         })
//                       }}
//                     />
//                   </FormGroup>
//                 </Col>


//                 <Col md={3} >
//                   <FormGroup>
//                     <Label className="forlabel">
//                       Vehicle No
//                     </Label>
//                     <Input
//                       type="text"
//                       name="vehicleNo"
//                       placeholder="Enter Vehicle Number"
//                       className=""
//                       value={searchCriteria.vehicleNo}
//                       onChange={handleChange}
//                     />
//                   </FormGroup>
//                 </Col>


//                 <Col md={3}>
//                   <FormGroup>
//                     <Label className="forlabel" for="branchId">Select Console</Label>
//                     <Select
//                       options={consoleArray}
//                       value={selectedConsole}
//                       onChange={handleConsoleChange}
//                       isClearable
//                       placeholder="Select Console"
//                       styles={{
//                         control: (provided, state) => ({
//                           ...provided,
//                           border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
//                           boxShadow: 'none',
//                           '&:hover': {
//                             border: '1px solid #ccc'
//                           }
//                         }),
//                         indicatorSeparator: () => ({
//                           display: 'none'
//                         }),
//                         dropdownIndicator: () => ({
//                           display: 'none'
//                         })
//                       }}
//                     />
//                   </FormGroup>
//                 </Col>


//                 <Col md={2} >
//                   <FormGroup>
//                     <Label className="forlabel">
//                       Custodian
//                     </Label>

//                     <Input
//                       type="text"
//                       name="Custodian"
//                       placeholder="Enter Custodian Name"
//                       className=""
//                       value={searchCriteria.Custodian}
//                       onChange={handleChange}
//                     />
//                   </FormGroup>
//                 </Col>


//               </Row>

//               <div className="d-flex justify-content-center align-items-center">
//                 <div className='mt-2'>
//                   <Button
//                     onClick={() => handleSearch(searchCriteria)}
//                     variant="outline-primary"
//                     style={{ marginRight: 20, fontWeight: 'bold' }}
//                   >
//                     <FontAwesomeIcon
//                       icon={faSearch}
//                       style={{ marginRight: "5px" }}
//                     />
//                     Search
//                   </Button>
//                   <Button
//                     onClick={handleResetButtonClick}
//                     variant="outline-danger"
//                     style={{ marginLeft: 9, fontWeight: 'bold' }}
//                   >
//                     <FontAwesomeIcon
//                       icon={faSyncAlt}
//                       style={{ marginRight: "5px" }}
//                     />
//                     Reset
//                   </Button>
//                 </div>
//               </div>
//             </Form>
//             <div hidden={!tpdata.length > 0}>
//               <hr />
//               <Row>
//                 <Col className="text-end">
//                   <button
//                     style={{ marginLeft: 9, fontWeight: 'bold' }}
//                     className="btn btn-outline-primary btn-margin"
//                     type="button"
//                     onClick={() => handlePrint("PRINT")}
//                   >
//                     <FontAwesomeIcon
//                       icon={faPrint}
//                       style={{ marginRight: "5px" }}
//                     />
//                     Print
//                   </button>
//                   <button
//                     className="btn btn-outline-primary btn-margin"
//                     type="button"
//                     onClick={() => handlePrint("PDF")}
//                     style={{ marginLeft: "10px" }}
//                   >
//                     <FontAwesomeIcon
//                       icon={faFilePdf}
//                       style={{ marginRight: "5px", fontWeight: 'bold' }}
//                     />
//                     PDF
//                   </button>
//                   <button
//                     className="btn btn-outline-success btn-margin"
//                     type="button"
//                     style={{ marginLeft: "10px", marginRight: 9, fontWeight: 'bold' }}
//                     onClick={() => getExcel(tpdata)}
//                   >
//                     <FontAwesomeIcon
//                       icon={faFileExcel}
//                       style={{ marginRight: "5px", fontWeight: 'bold' }}
//                     />
//                     XLS
//                   </button>
//                 </Col>
//               </Row>


//               <div className="table-responsive mt-2" >
//                 <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
//                   <thead className="thead-dark bg-dark">
//                     <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Sr No</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Parcel Type</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Exporter Name</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>No of Pkg</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Description</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Value Rs.</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>Destination</th>
//                     </tr>
//                   </thead>
//                   <thead>
//                     <tr className="text-center">
//                       <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>{tpdata.length}</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}> </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}> </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}> </th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>{calculateTotal(tpdata, 3)}</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                       <th style={{ backgroundColor: "#BADDDA" }}>{calculateTotal(tpdata, 5)}</th>
//                       <th style={{ backgroundColor: "#BADDDA" }}></th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {currentItems.map((item, index) => (
//                       <tr key={index} className="text-center">
//                         <td>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
//                         <td>{item[0]}</td>
//                         <td>{formatedDate(item[1])}</td>
//                         <td>{item[7] === 'Y' ? 'SC' : item[8] === 'Y' ? 'P' : item[9] === 'Y' ? 'HW' : ''}</td>
//                         <td>{item[2]}</td>
//                         <td>{item[3]}</td>
//                         <td>{item[4]}</td>
//                         <td>{item[5]}</td>
//                         <td>{item[6]}</td>
//                       </tr>
//                     ))}
//                     <tr className="text-center dynamic-row-width total-row">
//                       <td>Total</td>
//                       <td></td>
//                       <td></td>
//                       <td></td>
//                       <td></td>
//                       <td>{calculateTotal(currentItems, 3)}</td>
//                       <td></td>
//                       <td>{calculateTotal(currentItems, 5)}</td>
//                       <td></td>
//                     </tr>

//                   </tbody>
//                 </Table>

//                 <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
//                   <Pagination.First onClick={() => handlePageChange(1)} />
//                   <Pagination.Prev
//                     onClick={() => handlePageChange(currentPage - 1)}
//                     disabled={currentPage === 1}
//                   />
//                   <Pagination.Ellipsis />

//                   {displayPages().map((pageNumber) => (
//                     <Pagination.Item
//                       key={pageNumber}
//                       active={pageNumber === currentPage}
//                       onClick={() => handlePageChange(pageNumber)}
//                     >
//                       {pageNumber}
//                     </Pagination.Item>
//                   ))}

//                   <Pagination.Ellipsis />
//                   <Pagination.Next
//                     onClick={() => handlePageChange(currentPage + 1)}
//                     disabled={currentPage === totalPages}
//                   />
//                   <Pagination.Last onClick={() => handlePageChange(totalPages)} />
//                 </Pagination>

//               </div>


//             </div>
//           </CardBody>
//         </Card>
//       </div>

//     </>
//   );
// }








import AuthContext from "../Components/AuthProvider";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useContext, useRef } from "react";
import "../Components/Style.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import ipaddress from "../Components/IpAddress";
import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { Pagination } from "react-bootstrap";
import { saveAs } from "file-saver";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import { faArrowsToEye, faBorderAll, faEye, faFileArrowUp, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  faCheck,
  faSave,
  faTimes,
  faSyncAlt,
  faFileExcel,
  faFilePdf,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import moment from 'moment';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import Rate_Chart_Service from "../services/Rate_Chart_Service";

export default function ExportTp() {
  const navigate = useNavigate();

  const {
    jwtToken,
    userId,
    username,
    branchId,
    companyid,
    role,
    companyname,
    branchname,
    isAuthenticated,
    login,
    logout,
  } = useContext(AuthContext);




  const [loading, setLoading] = useState(false);
  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    },
  };


  const [tpdata, setTpdata] = useState([]);
  const [errors, setErrors] = useState({});


  const formatedDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const [partys, setPartys] = useState([]);
  const [getpartyId, setGetpartyId] = useState({});


  // If the user is not authenticated, redirect to the login page
  useEffect(() => {
    if (!isAuthenticated) {
      navigate(
        "/login?message=You need to be authenticated to access this page."
      );
    }
  }, [isAuthenticated, navigate]);

  const [consoleArray, setConsoleArray] = useState([])
  const [selectedConsole, setSelectedConsole] = useState(null);
  const [tpNoArray, setTpNoArray] = useState([]);
  const [selectedTpNo, setSelectedTpNo] = useState(null);



  const formatDate = (date) => {
    if (!date) {
      date = new Date();
    }
    return moment(date).format('YYYY-MM-DD');
  };


  const initialSearchCriteria = {
    companyId: companyid,
    branchId: branchId,
    tpNo: '',
    startDate: formatDate(new Date()),
    endDate: formatDate(new Date()),
    vehicleNo: '',
    Custodian: 'Sunil Patil',
    console: '',
    userType: 'console',
    sealNo: ''
  };
  const [searchCriteria, setSearchCriteria] = useState(initialSearchCriteria);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchCriteria((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };


  const findTpNumbers = async () => {
    setLoading(true);
    setTpNoArray([]);
    setSelectedTpNo(null);
    setTpdata([]);
    try {
      const resultSet = await Rate_Chart_Service.searchTp({ params: searchCriteria });
      setTpNoArray(resultSet.data);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
    finally {
      setLoading(false);
    }
  }



  const findConsoles = async () => {
    setLoading(true);
    setConsoleArray([]);
    setSelectedConsole(null);
    try {
      const partyOptions = await Rate_Chart_Service.searchConsoleForExportTp({ params: searchCriteria })
      setConsoleArray(partyOptions.data);
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
    finally {
      setLoading(false);
    }
  }



  // Effect to call findTpNumbers when tpDate changes
  useEffect(() => {
    findTpNumbers();
  }, [searchCriteria.startDate]); // Dependency array

  useEffect(() => {
    findConsoles();
  }, [searchCriteria.tpNo, searchCriteria.startDate]); // Dependency array



  // Handle change for date input
  const handleDateChange = (date) => {
    setSearchCriteria((prevState) => ({
      ...prevState,
      startDate: formatDate(date),
      endDate: formatDate(date)
    }));


    if (errors.startDate) {
      const updatedErrors = { ...errors };
      delete updatedErrors.startDate;
      setErrors(updatedErrors);
    }
  };


  const handleSearch = async (searchCriteria) => {

    const error = {};
    // Validate startDate
    if (!searchCriteria.startDate) {
      error.startDate = "Start Date is required";
    }
    // Validate tpNo
    if (!searchCriteria.tpNo) {
      error.tpNo = "TP No is required";
    }
    // Check if any errors exist
    if (Object.keys(error).length > 0) {

      toast.error("Select required fields", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1200,
      });
      setErrors(error);
      // Return early if there are errors
      return;
    }


    setCurrentPage(1)
    setLoading(true);
    try {
      const response = await Rate_Chart_Service.searchExportTpData({ params: searchCriteria });
      const data = response.data;
      if (data && data.length > 0) {
        setTpdata(data);
        console.log("tp dataaa \n", data);
      } else {
        setTpdata([]);
      }
      // setError([]);
    } catch (error) {
      console.error("Error fetching data:", error);
      // setError(error); // Set the error state if there's an error
    } finally {
      setLoading(false);
    }
  };


  const handleResetButtonClick = async () => {
    setSearchCriteria(initialSearchCriteria);
    setTpdata([]);
    setSelectedTpNo(null);
    setSelectedConsole(null);
    // await handleSearch(initialSearchCriteria);
    setErrors([]);
  };









  function numberToWordsLessThanThousand(number) {
    const words = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    const tensWords = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    let wordsString = '';

    if (number > 99) {
      wordsString += words[Math.floor(number / 100)] + " Hundred ";
      number %= 100;
    }

    if (number > 19) {
      wordsString += tensWords[Math.floor(number / 10)];
      number %= 10;
    }

    if (number > 0) {
      wordsString += words[number];
    }

    return wordsString;
  }




  function getCurrentDateTimeFormatted() {
    const currentDate = new Date();

    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear() % 100; // Get the last two digits of the year
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = String(minutes).padStart(2, "0");

    const formattedDateTime = `${month}/${day}/${year}, ${formattedHours}:${formattedMinutes} ${period}`;

    return formattedDateTime;
  }



  //Export TP

  const formatDateTime2 = (value) => {
    if (!value) {
      return "";
    }
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} `;
  };
  const getExcel = async (imp) => {
    const filename = `Export_TP_${formatDateTime2(new Date())}.xlsx`;
    setLoading(true);
    try {
      const response = await axios.post(`https://${ipaddress}export/tpexcel/${companyid}/${branchId}`, imp, { responseType: 'blob' });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      toast.error("Something went wrong", {
        autoClose: 700
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = async (type) => {
    setLoading(true);
    try {
      const response = await Rate_Chart_Service.generateExportTpPrint(searchCriteria, tpdata);

      if (response.status === 200) {
        const pdfData = response.data;
        const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(pdfBlob);

        if (type === "PDF") {
          // Create an anchor element for downloading
          const downloadLink = document.createElement('a');
          downloadLink.href = blobUrl;
          downloadLink.download = 'Export_Tp.pdf';
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          // Trigger the download
          downloadLink.click();
          // Clean up
          document.body.removeChild(downloadLink);
          window.URL.revokeObjectURL(blobUrl);
        }
        if (type === 'PRINT') {
          window.open(blobUrl, '_blank');
        }
        toast.success("Downloading Pdf!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 800,
        });
      } else {
        toast.error("error downLoading file!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 800,
        });
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
    finally {
      setLoading(false);
    }
  };


  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30);



  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tpdata.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(tpdata.length / itemsPerPage);

  // Function to handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const displayPages = () => {
    const centerPageCount = 5;
    const middlePage = Math.floor(centerPageCount / 2);
    let startPage = currentPage - middlePage;
    let endPage = currentPage + middlePage;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(totalPages, centerPageCount);
    }

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, totalPages - centerPageCount + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  // Function to calculate the total for a specific column
  const calculateTotal = (data, columnIndex) => {
    const total = data.reduce((sum, row) => sum + (parseFloat(row[columnIndex]) || 0), 0);
    // Check if the total has a fractional part
    const roundedTotal = total % 1 !== 0 ? parseFloat(total.toFixed(2)) : total;

    return roundedTotal !== 0 ? roundedTotal : ''; // Return the rounded total or an empty string if zero
  };

  const handleConsoleChange = selectedOption => {
    setSelectedConsole(selectedOption);
    setSearchCriteria({ ...searchCriteria, console: selectedOption ? selectedOption.value : '' });
    setTpdata([]);
  };

  const handleTpNoChange = async selectedOption => {
    setSearchCriteria({ ...searchCriteria, console: '' });
    setConsoleArray([]);
    setSelectedConsole(null);
    setSelectedTpNo(selectedOption);
    setSearchCriteria({ ...searchCriteria, tpNo: selectedOption ? selectedOption.value : '' });
    if (errors.tpNo) {
      const updatedErrors = { ...errors };
      delete updatedErrors.tpNo;
      setErrors(updatedErrors);
    }
  };




  return (

    <>
      {loading && (
        <div style={styles.overlay}>
          <ReactLoading type="spin" color="#0000FF" height={300} width={80} />
        </div>
      )}
      <div className="Container" >




        <h5 className="pageHead" style={{ fontFamily: 'Your-Heading-Font', paddingLeft: '2%', paddingRight: '-20px' }} > <FontAwesomeIcon
          icon={faFileArrowUp}
          style={{
            marginRight: '8px',
            color: 'black', // Set the color to golden
          }}
        />Export TP Reports</h5>
        <Card >
          <CardBody>
            <Form>
              <Row>
                <Col md={2}>
                  <FormGroup>
                    <Label className="forlabel" for="branchId">Tp Date</Label><span className='error'>*</span>

                    <DatePicker
                      selected={searchCriteria.startDate ? new Date(searchCriteria.startDate) : null}
                      wrapperClassName="custom-react-datepicker-wrapper"
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy" // Specify the combined format
                      className={`form-control border-right-0 inputField ${errors.startDate ? 'error-border' : ''}`}
                      customInput={<input style={{ width: '100%' }} />}
                    />

                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label className="forlabel" for="typeOfProduct">Select Tp No</Label><span className='error'>*</span>

                    <Select
                      options={tpNoArray}
                      value={selectedTpNo}
                      onChange={handleTpNoChange}
                      isClearable
                      className={errors.tpNo ? 'error-border' : ''}
                      placeholder="Select Tp No"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                          // borderColor: errors.consoleName ? '#f52b2b' : '',
                          boxShadow: 'none',
                          '&:hover': {
                            border: '1px solid #ccc'
                          }
                        }),
                        indicatorSeparator: () => ({
                          display: 'none'
                        }),
                        dropdownIndicator: () => ({
                          display: 'none'
                        })
                      }}
                    />
                  </FormGroup>
                </Col>


                <Col md={2} >
                  <FormGroup>
                    <Label className="forlabel">
                      Vehicle No
                    </Label>
                    <Input
                      type="text"
                      name="vehicleNo"
                      placeholder="Enter Vehicle Number"
                      className=""
                      value={searchCriteria.vehicleNo}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>


                <Col md={2} >
                  <FormGroup>
                    <Label className="forlabel">
                      Seal No
                    </Label>
                    <Input
                      type="text"
                      name="sealNo"
                      placeholder="Enter Seal Number"
                      className=""
                      value={searchCriteria.sealNo}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>


                <Col md={2}>
                  <FormGroup>
                    <Label className="forlabel" for="branchId">Select Console</Label>
                    <Select
                      options={consoleArray}
                      value={selectedConsole}
                      onChange={handleConsoleChange}
                      isClearable
                      placeholder="Select Console"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: state.isFocused ? '1px solid #ccc' : '1px solid #ccc',
                          boxShadow: 'none',
                          '&:hover': {
                            border: '1px solid #ccc'
                          }
                        }),
                        indicatorSeparator: () => ({
                          display: 'none'
                        }),
                        dropdownIndicator: () => ({
                          display: 'none'
                        })
                      }}
                    />
                  </FormGroup>
                </Col>


                <Col md={2} >
                  <FormGroup>
                    <Label className="forlabel">
                      Custodian
                    </Label>

                    <Input
                      type="text"
                      name="Custodian"
                      placeholder="Enter Custodian Name"
                      className=""
                      value={searchCriteria.Custodian}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>


              </Row>

              <div className="d-flex justify-content-center align-items-center">
                <div className='mt-2'>
                  <Button
                    onClick={() => handleSearch(searchCriteria)}
                    variant="outline-primary"
                    style={{ marginRight: 20, fontWeight: 'bold' }}
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ marginRight: "5px" }}
                    />
                    Search
                  </Button>
                  <Button
                    onClick={handleResetButtonClick}
                    variant="outline-danger"
                    style={{ marginLeft: 9, fontWeight: 'bold' }}
                  >
                    <FontAwesomeIcon
                      icon={faSyncAlt}
                      style={{ marginRight: "5px" }}
                    />
                    Reset
                  </Button>
                </div>
              </div>
            </Form>
            <div hidden={!tpdata.length > 0}>
              <hr />
              <Row>
                <Col className="text-end">
                  <button
                    style={{ marginLeft: 9, fontWeight: 'bold' }}
                    className="btn btn-outline-primary btn-margin"
                    type="button"
                    onClick={() => handlePrint("PRINT")}
                  >
                    <FontAwesomeIcon
                      icon={faPrint}
                      style={{ marginRight: "5px" }}
                    />
                    Print
                  </button>
                  <button
                    className="btn btn-outline-primary btn-margin"
                    type="button"
                    onClick={() => handlePrint("PDF")}
                    style={{ marginLeft: "10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      style={{ marginRight: "5px", fontWeight: 'bold' }}
                    />
                    PDF
                  </button>
                  <button
                    className="btn btn-outline-success btn-margin"
                    type="button"
                    style={{ marginLeft: "10px", marginRight: 9, fontWeight: 'bold' }}
                    onClick={() => getExcel(tpdata)}
                  >
                    <FontAwesomeIcon
                      icon={faFileExcel}
                      style={{ marginRight: "5px", fontWeight: 'bold' }}
                    />
                    XLS
                  </button>
                </Col>
              </Row>


              <div className="table-responsive mt-2" >
                <Table className="table table-bordered table-hover" style={{ border: '2px solid black' }}>
                  <thead className="thead-dark bg-dark">
                    <tr className='text-center' style={{ backgroundColor: '#BADDDA', fontWeight: 'bold', border: '2px solid black', fontSize: '17px', color: 'black', fontFamily: 'Your-Heading-Font', height: '60px' }}>
                      <th style={{ backgroundColor: "#BADDDA" }}>Sr No</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>SER No</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>SER Date</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>Parcel Type</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>Exporter Name</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>No of Pkg</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>Description</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>Value Rs.</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>Destination</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr className="text-center">
                      <th style={{ backgroundColor: "#BADDDA" }}>Total</th>
                      <th style={{ backgroundColor: "#BADDDA" }}>{tpdata.length}</th>
                      <th style={{ backgroundColor: "#BADDDA" }}> </th>
                      <th style={{ backgroundColor: "#BADDDA" }}> </th>
                      <th style={{ backgroundColor: "#BADDDA" }}> </th>
                      <th style={{ backgroundColor: "#BADDDA" }}>{calculateTotal(tpdata, 3)}</th>
                      <th style={{ backgroundColor: "#BADDDA" }}></th>
                      <th style={{ backgroundColor: "#BADDDA" }}>{calculateTotal(tpdata, 5)}</th>
                      <th style={{ backgroundColor: "#BADDDA" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr key={index} className="text-center">
                        <td>{((currentPage - 1) * itemsPerPage) + index + 1}</td>
                        <td>{item[0]}</td>
                        <td>{formatedDate(item[1])}</td>
                        <td>{item[7] === 'Y' ? 'SC' : item[8] === 'Y' ? 'P' : item[9] === 'Y' ? 'HW' : ''}</td>
                        <td>{item[2]}</td>
                        <td>{item[3]}</td>
                        <td>{item[4]}</td>
                        <td>{item[5]}</td>
                        <td>{item[6]}</td>
                      </tr>
                    ))}
                    <tr className="text-center dynamic-row-width total-row">
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{calculateTotal(currentItems, 3)}</td>
                      <td></td>
                      <td>{calculateTotal(currentItems, 5)}</td>
                      <td></td>
                    </tr>

                  </tbody>
                </Table>

                <Pagination style={{ display: 'flex', justifyContent: 'center', color: 'gray' }}>
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  <Pagination.Ellipsis />

                  {displayPages().map((pageNumber) => (
                    <Pagination.Item
                      key={pageNumber}
                      active={pageNumber === currentPage}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </Pagination.Item>
                  ))}

                  <Pagination.Ellipsis />
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                  <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                </Pagination>

              </div>


            </div>
          </CardBody>
        </Card>
      </div>

    </>
  );
}


