import axios from "axios";
import ipaddress from "../Components/IpAddress";


const exportSub_url = `https://${ipaddress}exportsub/`



class processService {

    deleteSubHeavy(heavyObject, userId) {
        return axios.put(`${exportSub_url}deleteSubHeavy`, heavyObject,
            {
                params: {
                    user: userId
                }
            });
    };



    deleteSubImpHeavy(heavyObject, userId) {
        return axios.put(`${exportSub_url}deleteSubImpHeavy`, heavyObject,
            {
                params: {
                    user: userId
                }
            });
    };


  
    saveOrUpdateHeavyPackagesIMP(heavyObject, userId) {
        return axios.put(`${exportSub_url}saveOrUpdateHeavyPackagesIMP`, heavyObject,
            {
                params: {
                    user: userId
                }
            });
    };

    
    saveOrUpdateHeavyPackagesEXP(heavyObject, userId) {
        return axios.put(`${exportSub_url}saveOrUpdateHeavyPackagesEXP`, heavyObject,
            {
                params: {
                    user: userId
                }
            });
    };

    updatePenaltySubExp(heavyObject, userId) {
        return axios.put(`${exportSub_url}updatePenaltySubExp`, heavyObject,
            {
                params: {
                    user: userId
                }
            });
    };

    updatePenaltySubImp(heavyObject, userId) {
        return axios.put(`${exportSub_url}updatePenaltySubImp`, heavyObject,
            {
                params: {
                    user: userId
                }
            });
    };




    getSingleExportSubRequest(companyid, branchId, expSubId, requestId, serNo, type) {
        return axios.get(`${exportSub_url}getSingleExportSubRequest`, {
            params: {
                companyId: companyid,
                branchId,
                expSubId,
                requestId,
                serNo,
                type
            }
        }
        );
    };


    getAllHeavyParcels(companyid, branchId, expSubId, requestId, serNo, type) {
        return axios.get(`${exportSub_url}getAllHeavyParcels`, {
            params: {
                companyId: companyid,
                branchId,
                expSubId,
                requestId,
                serNo,
                type
            }
        }
        );
    };

    getSingleHeavyParcel(companyid, branchId, expSubId, requestId, serNo, hppackageno, type) {
        return axios.get(`${exportSub_url}getSingleHeavyParcel`, {
            params: {
                companyId: companyid,
                branchId,
                expSubId,
                requestId,
                serNo,
                type, hppackageno
            }
        }
        );
    };




}
export default new processService(); 